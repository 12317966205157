.TipsModalTitle {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: #101650;
}
.Line-540 {
  height: 0;

  border: solid 1px #0674b9;
}
.modal-content {
  font-family: Muli;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #101650;
}
.SnoozeButton {
  height: 32px;
  padding: 5px;
  border-radius: 5px;
  border: solid 1px #b7daf7;
  background-color: #fff;
  font-family: Muli;
  font-weight: bold;
  font-size: 10px;
  line-height: 1.5;
  text-align: center;
  color: #101650;
}
.SnoozeButton:hover {
  background-color: #101650;
  color: #fff;
  cursor: pointer;
}
