.form-title {
    font-family: Muli;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #181818;
  }
  .formDesign {
    width: 450px;
  
    padding: 16px 27.9px 28px 26px;
    opacity: 0.79;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #eaf0f5;
    background-color: #fff;
  }
  @media only screen and (max-width: 600px) {
    .formDesign {
      width: 85%;
    }
  }
  .Fields-styles {
    width: 100%;
  
    border-radius: 5px;
    border: solid 0px #707070;
    background-color: #fff;
  
    font-family: Muli;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #181818;
  }
  .buttonNextForm {
    text-transform: none;
    border-radius: 5px;
    width: 71px;
    height: 33px;
    font-size: 19px;
    font-family: Muli;
    color: #fff;
    background-color: #0b5cab;
  
    border: 0px;
    padding: 3px;
  }
  .buttonNextForm:disabled {
    opacity: 0.6;
    color: #fff;
  }
  .form-number {
    margin-top: 2px;
    opacity: 0.7;
    font-family: Muli;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    color: #181818;
  }
  .checksquare {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: solid 1px #707070;
    background-color: #fff;
  }
  .agreePolicy {
    font-family: Muli;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #0b5cab;
  }
  .agreePolicy .text-style-1 {
    color: #181818;
  }
  .CheckBoxError {
    font-family: Muli;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #f00;
  }
  .agreeMessage {
    font-family: Muli;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #181818;
  }
  .agreeMessage .text-style-1 {
    color: #0b5cab;
  }
  .agreeMessage .text-style-2 {
    color: #101650;
  }
  .custom-styles {
    --ReactInputVerificationCode-itemWidth: 1.5rem;
    --ReactInputVerificationCode-itemHeight: 3.5rem;
  }
  
  .custom-styles .ReactInputVerificationCode__item {
    position: relative;
    color: #101650;
    font-weight: 500;
  }
  
  .custom-styles .ReactInputVerificationCode__item,
  .custom-styles .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
    line-height: 3.5;
  }
  
  .custom-styles .ReactInputVerificationCode__item:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 2px;
    background-color: #999999;
    transition: background-color 0.2s ease-out;
  }
  
  .custom-styles .ReactInputVerificationCode__item.is-active:after {
    background-color: #046cde;
  }
  
  
  .custom-styles1 {
    --ReactInputVerificationCode-itemWidth: 1rem;
    --ReactInputVerificationCode-itemHeight: 3.5rem;
  }
  
  .custom-styles1 .ReactInputVerificationCode__item {
    position: relative;
    color: #101650;
    font-weight: 500;
  }
  
  .custom-styles1 .ReactInputVerificationCode__item,
  .custom-styles1 .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
    line-height: 3.5;
  }
  
  .custom-styles1 .ReactInputVerificationCode__item:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 2px;
    width: 120%;
    background-color: red;
    transition: background-color 0.2s ease-out;
  }
  
  .custom-styles1 .ReactInputVerificationCode__item.is-active:after {
    background-color: #046cde;
  }
  
  .notMatch {
      font-family: Muli;
      font-size: 10px;
      line-height: 1.45;
      text-align: left;
      color: #f00;
      margin-top:10px ;
  }