.Production-Order {
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.PRO-DEMO---0001 {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.next-button {
  padding: 7.8px 31.5px;
  background-color: #101650;
  font-family: Muli;
  font-size: 19px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  border-radius: 5px;
}

.next-button:hover {
  cursor: pointer;
}

.next-button:disabled {
  cursor: default;
}
.Percentage-Of-Accomplishment-60 {
  font-family: Muli;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.Ellipse-524 {
  width: 27px;
  height: 27px;
  border: solid 2px #e3e4a7;
  border-radius: 20px;
  cursor: pointer;
}

.Percentage-Of-Accomplishment {
  font-family: Muli;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.filled-Ellipse-524 {
  width: 27px;
  height: 27px;
  border: solid 2px #e3e4a7;
  border-radius: 20px;
  background-color: rgba(225, 227, 25, 0.5);
  cursor: pointer;
}

.prod-Ellipse-524 {
  width: 27px;
  height: 27px;
  border: solid 2px #e3e4a7;
  border-radius: 20px;
  background-color: "#000";
}

.prod-Ellipse-524:hover{
  cursor: pointer;
}
