

.Title {
    color: "#101650";
    font-size: 20px;
    font-family: "Muli";
    font-weight: "bold";
    
}

.line{
  height: 0;
  border: solid 1px #fff;
  background-color: #f2f2f2;
}

.ViewText{
    color: "#101650";
    font-size: 13px;
    font-family: "Muli";

}

.createSalesOrderButton{

    font-family: Muli;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #101650;
  background-color: #e1e319;
    
}

.disciplineTrade{
    font-family: Muli;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}

.back-button {
  width: 57px;
  border-radius: 5px;
  height: 30px;
  border: 0;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #444;
}

.viewCalender {
  font-family: Muli;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.47;
  text-align: left;
  color: rgba(6, 116, 185, 0.5);
}

.hintMsg{
  color: rgba(6, 116, 185, 0.5);;
  font-size: 15px;
  font-family: "Muli";

}