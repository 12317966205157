.RegBox {
  width: 450px;

  padding: 16px 27.9px 28px 26px;
  opacity: 0.79;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #eaf0f5;
  background-color: #fff;
}
@media only screen and (max-width: 500px) {
  .RegBox {
    width: 93%;
  }
}
.Join-Pilecubes {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #181818;
}
.closeIcon {
  width: 17;
  height: 17;
}
.infoIcon {
  width: 17;
  height: 17;
}
.Rectangle-49 {
  width: 395px;
  height: 30px;

  border-radius: 5px;
  border: solid 1px #707070;
  background-color: #fff;
}
.textField {
  width: 100%;

  border-radius: 5px;
  border: solid 1px #707070;
  background-color: #fff;

  font-family: Muli;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #181818;
}
.textField2 {
  width: 94%;

  border-radius: 5px;
  border: solid 1px #707070;
  background-color: #fff;

  font-family: Muli;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #181818;
}
.textFieldLabel {
  font-family: Muli;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #181818;
}
.nextButton {
  border-radius: 5px;
  background-color: #0b5cab;
  font-family: Muli;
  font-size: 19px;
  text-transform: none;
}

.step-1-of-3 {
  margin-top: 2px;
  opacity: 0.7;
  font-family: Muli;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #181818;
}
.shrink {
  width: 135%;
  transform: translate(0, 0px) scale(0.75);
  transform-origin: top left;
  border-color: #707070;
}
.contactInfo {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #181818;
}
.agreement {
  font-family: Muli;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #0b5cab;
}
.agreement .text-style-1 {
  color: #181818;
}
.policy {
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #181818;
}
.policy .text-style-1 {
  color: #0b5cab;
}
.policy .text-style-2 {
  color: #101650;
}
.agreeError {
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #f00;
}
.checkBox {
  width: 16px;
  height: 16px;

  border-radius: 3px;
  border: solid 1px #707070;
  background-color: #fff;
}
.buttonNext {
  text-transform: none;
  border-radius: 5px;
  width: 71px;
  height: 32px;
  font-size: 19px;
  font-family: Muli;
  color: #fff;
  background-color: #0b5cab;
 
  padding:3px;
  border: 0px;
}
.buttonNext:disabled {
  opacity: 0.6;
  color: #fff;
}

.registerLogo {
}

.subR {
}

@media only screen and (max-width: 960px) {
  .registerLogo {
    display: none;
  }
  .subR {
    margin-top: 50px;
  }
}
