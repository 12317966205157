.title{
    font-family: Muli;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #101650;
  }

  .back-button {
    width: 57px;
    border-radius: 5px;
    height: 30px;
    border: 0;
    background-color: #fff;
    font-family: Muli;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #444;
  }
  .back-button:hover {
    background-color: #bcbcbc;
    cursor: pointer;
    color: #fff;
  }

  .monthyear{
    font-family: Muli;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #101650;
}

.Day{
    font-family: Muli;
    font-size: 12px;
    text-align: left;
    color: #101650;
    font-weight: "bold";
}

.Date{
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
  position: sticky;
  top:0px;
  background-color: #fff;
  width:100%;
  height: 100%;
  
}

.CardData{
    font-family: Muli;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
}

.SelectOrdersButton{
    background-color: #fff;
    font-family: Muli;
    font-size: 8.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #101650;
    border: 0;
    border-radius: 2px;
    

}

.SelectOrdersButton:hover{
    background-color: #101650;
    color: #fff;

}
.SOCode {
  font-family: Muli;
  font-size: 8px;
  line-height: 1.25;
  text-align: left;
  color: #000;
}