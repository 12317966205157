.custom-styles {
  --ReactInputVerificationCode-itemWidth: 1.5rem;
  --ReactInputVerificationCode-itemHeight: 3.5rem;
}

.custom-styles .ReactInputVerificationCode__item {
  position: relative;
  color: #101650;
  font-weight: 500;
}

.custom-styles .ReactInputVerificationCode__item,
.custom-styles .ReactInputVerificationCode__item.is-active {
  box-shadow: none;
  line-height: 3.5;
}

.custom-styles .ReactInputVerificationCode__item:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 2px;
  background-color: #999999;
  transition: background-color 0.2s ease-out;
}

.custom-styles .ReactInputVerificationCode__item.is-active:after {
  background-color: #046cde;
}


.custom-styles1 {
  --ReactInputVerificationCode-itemWidth: 1rem;
  --ReactInputVerificationCode-itemHeight: 3.5rem;
}

.custom-styles1 .ReactInputVerificationCode__item {
  position: relative;
  color: #101650;
  font-weight: 500;
}

.custom-styles1 .ReactInputVerificationCode__item,
.custom-styles1 .ReactInputVerificationCode__item.is-active {
  box-shadow: none;
  line-height: 3.5;
}

.custom-styles1 .ReactInputVerificationCode__item:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 2px;
  width: 120%;
  background-color: red;
  transition: background-color 0.2s ease-out;
}

.custom-styles1 .ReactInputVerificationCode__item.is-active:after {
  background-color: #046cde;
}

.notMatch {
    font-family: Muli;
    font-size: 10px;
    line-height: 1.45;
    text-align: left;
    color: #f00;
    margin-top:10px ;
}