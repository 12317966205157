
  .title{
    font-family: Muli;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #101650;
  }

  .back-button {
    width: 57px;
    border-radius: 5px;
    height: 30px;
    border: 0;
    background-color: #fff;
    font-family: Muli;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #444;
  }
  .back-button:hover {
    background-color: #bcbcbc;
    cursor: pointer;
    color: #fff;
  }

  .add-button {
    width: 57px;
    height: 30px;
    border: 0;
    background-color: #fff;
    font-family: Muli;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #0674b9;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(16, 22, 80, 0.5);
  }
  .add-button:hover {
    background-color: #0674b9;
    cursor: pointer;
    color: #fff;
  }
  .update-button {
    height: 30px;
    border: 0;
    background-color: #fff;
    font-family: Muli;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #0674b9;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(16, 22, 80, 0.5);
  }
  .update-button:hover {
    background-color: #0674b9;
    cursor: pointer;
    color: #fff;
  }

  .Rectangle-1224 {
    padding: 10px;
    border-radius: 5px;
    border: 0;
    background-color: transparent;
    font-family: Muli;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #444;
  }
  .Fields-Titles {
    font-family: Muli;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: rgba(16, 22, 80, 0.7);
  }
  .Field-inputs {
    width: 95%;
    height: 40px;
  
    border-radius: 5px;
    border: 0;
    border: solid 1px #f2f2f2;
  }
  .button-save {
    width: 95%;
    height: 40px;
    border: 0;
    border-radius: 5px;
    background-color: #0b5cab;
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .button-save:disabled {
    opacity: 0.7;
    background-color: #f2f2f2;
   cursor: none;
    /* color: #0b5cab; */
  }