.LoginBg {
  background-image: url(../Images//Login//logInBg.png);
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.FormBg {
  border-radius: 8px;
  border: solid 1px #f2f2f2;
  background-color: #fff;
}

.LoginText {
  width: 156px;
  height: 20px;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: rgba(16, 22, 80, 0.7);
}
.FieldsInput {
  width: 338px;

  border-radius: 4px;
  border: solid 1px #f2f2f2;
  background-color: #fff;
}
.FiedLabels {
  width: 32px;
  height: 18px;

  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: rgba(16, 22, 80, 0.7);
}
.loginButton {
  width: 338px;
  height: 42.8px;
  border: 0;
  border-radius: 4px;
  background-color: #1173cf;

  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.RememberMe {
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  color: rgba(16, 22, 80, 0.7);
}

.HorizontalLine {
  width: 338px;
  height: 0;
  border: solid 0.9px #f2f2f2;
}

.LoginLinks {
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: #0674b9;
}
.Forgot-Your-Password {
  width: 150px;
  height: 20px;

  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: rgba(16, 22, 80, 0.7);
}
.logging-in-To-reset {
  width: 316px;
  height: 36px;

  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: rgba(16, 22, 80, 0.7);
}
.cancel-button {
  width: 164px;
  height: 42.9px;
  border: 0;
  border-radius: 4px;
  border: solid 1px #f2f2f2;
  background-color: #fff;

  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #0674b9;
}
.continue-button {
  width: 164px;
  height: 42.6px;
  border: 0;

  border-radius: 4px;
  background-color: #1173cf;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.CheckEmail {
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: center;
  color: rgba(16, 22, 80, 0.7);
}

.CheckEmailText {
  width: 344px;
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: rgba(16, 22, 80, 0.7);
}

.return-button {
  width: 338px;
  height: 42.8px;
  padding: 12px 124px 12.8px;
  border-radius: 4px;
  border: solid 1px #f2f2f2;
  background-color: #1173cf;
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: #fff;
}
.Reset-Your-Pasword {
  width: 136px;
  height: 20px;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: rgba(16, 22, 80, 0.7);
}
.login-errors {
  font-family: Muli;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #f96161;
}

.buttontest {
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  background-color: #1173cf;
  font-family: Muli;
  font-size: 20px;
  line-height: 1.5;
  color: #fff;
  direction: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px 5px 10px;
  width: 150px;
  
}
.buttontest:hover{
  background-color: #101650;
}

.buttontest:disabled {
  opacity: 0.5;
}
