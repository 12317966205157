.navBar {
  color: #fff;
}
.email {
  text-decoration: none;
  font-family: Muli;
  font: size 18px;
  line-height: 1.5;
  text-align: left;
  color: #101650;
  margin-top: 1.5%;
}

/* .demobutton {
  margin-left: 9%;
}

@media only screen and (max-width: 1280px) {
  .demobutton {
    margin-left: 11%;
  }
} */
.iot{
  background-color: #fff;
}

.iot:hover{
  box-shadow: 0 3px 6px 0 rgba(225, 227, 25, 0.5);
  background-color: #f1f1f1;
  border-radius: 50px;
  margin-bottom: 10px;
}

.back-button {
  width: 57px;
  border-radius: 5px;
  height: 30px;
  border: 0;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #444;
}
.back-button:hover {
  background-color: #bcbcbc;
  cursor: pointer;
  color: #fff;
}

.login {
  text-decoration: none;
  margin-left: 3%;
  font-family: Muli;
  font: size 18px;
  line-height: 1.5;
  text-align: left;
  color: #101650;
  margin-top: 1.5%;
}
.accountName {
  text-decoration: none;
  margin-left: 5%;
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: #101650;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mainDiv {
  display: flex;
  flex-direction: row;
}

.verLine {
  margin-left: 3%;
  height: 30px;
  border-left: solid 3px #e1e319;
}
.loginDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: 65%;
  gap: 3%;
}

.bottomNav {
  display: flex;
  flex-direction: row;
  gap: 5%;
  margin-left: 13%;
  padding-bottom: 0.5%;
}

.navButtons {
  border-bottom: 3px solid #fff;
  font-family: Muli;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #101650;
}

.list-container {
  display: flex;
  align-items: center;
  list-style: none;
}

ul li a {
  text-decoration: none;
  margin-left: 60px;
  border-bottom: 3px solid transparent;
  border-top: 3px solid transparent;
  font-family: Muli;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #101650;
}

ul li a:hover {
  font-weight: bold;
}

.active {
  font-weight: bold;
  border-bottom-color: #e1e319;
}

.buttonBook {
  width: 140px;
  font-weight: bold;
  font-size: 16px;
  border-radius: 7px;
  background-color: #e1e319;
  border: #e1e319;
  color: #fff;
  padding: 4%;
}

.homeDrawer {
  font-family: Muli;
  font-size: 15px;
  line-height: 1.47;
  text-align: left;
  color: #0674b9;
}

.buttonsDrawer {
  font-family: Muli;
  font-size: 15px;
  line-height: 1.47;
  text-align: left;
  color: #0674b9;
  text-decoration: none;
  font-weight: normal;
}
.buttonsDrawerActive {
  color: #0674b9;
  width: fit-content;
  border-bottom: 2px solid #e1e319;
}
.submitButton1 {
  border: 0;
  text-transform: none;
  background-color: #e1e319;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px;
  font-family: Muli;
  font-weight: bold;
}

.submitButton1:disabled {
  opacity: 0.5;
}
.activeDrawer {
  font-size: 50;
  font-weight: bold;
  border-bottom-color: #e1e319;
}
.Logout {
  width: 54px;
  height: 24px;

  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;

  color: rgba(16, 22, 80, 0.7);
}
.NavTitle {

  font-family: Muli;
  font-size: 15px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #101650;
  margin-left: 60%;
}