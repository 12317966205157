.footerText {
  color: #101650;
}

.footerText :hover {
  font-weight: bold;
}
/* ::-webkit-scrollbar {
  display: none;
} */
html {
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 12px;
  height: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(239, 246, 248, 0.671);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 0.9px;
  -webkit-box-shadow: inset 0 0 6px rgba(10, 176, 226, 0.3);
}
.excalidraw-wrapper {
  width: 100%;
  height: 500px;
  position: relative;
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
  
  /* ... the rest of the rules ... */
}

.hintMsg{
  color: rgba(6, 116, 185, 0.5);;
  font-size: 15px;
  font-family: "Muli";

}