.Upgrade-subscription-required {
  font-family: Muli;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.Rectangle-1231 {
  padding: 5px;
  border-radius: 5px;
  background-color: #101650;
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  border: 0;
  color: #fff;
}
.Rectangle-1231:hover {
  cursor: pointer;
}
