.back-button {
  width: 57px;
  border-radius: 5px;
  height: 30px;
  border: 0;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #444;
}
.back-button:hover {
  background-color: #bcbcbc;
  cursor: pointer;
  color: #fff;
}
.save-button {
  padding: 7px 22px 6px 22px;
  border-radius: 5px;
  background-color: #0674b9;
  font-family: Muli;
  font-size: 13px;
  text-align: center;
  color: #fff;
  border: 0;
}
.save-button:hover {
  cursor: pointer;
  background-color: #101650;
}
.hold-button {
  padding: 0.7rem;
  border-radius: 5px;
  border: 0;
  background-color: #101650;
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin-top: 2rem;
}
.hold-button:hover {
  opacity: 0.5;
  cursor: pointer;
}
.AddMemberButton {
  padding: 4px 15px;
  border-radius: 5px;
  background-color: rgba(6, 116, 185, 0.7);
  font-family: Muli;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.47;
  text-align: center;
  color: #fff;
  border: 0;
}
.AddMemberButton:hover {
  cursor: pointer;
  background-color: #0674b9;
}
.Rectangle-1796 {
  height: 61px;
  padding: 6px 74px 7.7px 96px;
  background-color: rgba(225, 227, 25, 0.3);
  font-family: Muli;
  font-size: 11px;
  font-weight: bold;
  line-height: 0.91;
  text-align: center;
  align-items: center;
  color: #101650;
  overflow-x: scroll;
}
.SubTitle {
  font-family: Muli;
  font-size: 11px;
  font-weight: bold;
  line-height: 0.91;
  text-align: center;
  color: #0674b9;
}
.subsubTitiles {
  font-family: Muli;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.45;
  text-align: left;
  color: #707070;
}
.tabHe {
  height: 38px !important;
}
.CancelBut {
  width: 60px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  padding: 5px;
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: rgba(16, 22, 80, 0.7);
  border: 0;
}
.CancelBut:hover {
  cursor: pointer;
  background-color: #0674b9;
  color: #fff;
}
.Place-Order {
  width: 102px;
  padding: 5px;
  border-radius: 5px;
  background-color: #0b5cab;
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: #fff;
  border: 0;
}
.Place-Order:hover {
  background-color: #101650;
  cursor: pointer;
}
.Place-Order:disabled {
  background-color: #bcbcbc;
}
.Place-Order:disabled:hover {
  background-color: #bcbcbc;
  cursor: unset;
}
.ShapeDoesNot {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: #707070;
}
.Path-1273 {
  width: 145.7px;
  padding: 2.2px 6.8px 5.5px 9.2px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #0674b9;
  height: 50px;
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: #fff;
  border-radius: 5px;
}
.Path-1273:hover {
  cursor: pointer;
}

.productionButton {
  padding: 37px 48px 187px 47px;
  opacity: 0.8;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: #fff;
  background-color: #0674b9;
}
.ExceedWeight {
  background-color: #f0f18c;
  font-family: Muli;
  font-size: 18px;
  line-height: 1.25;
  text-align: center;
  color: #3a3a3a;
  position: fixed;
  top: 1;
  left: 0;
  width: 100%;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
