.Modal-Title {
  font-family: Muli;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: normal;
  text-align: left;
  color: #0674b9;
}
.Action-title {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.Permission-title {
  font-family: Muli;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.Action-permission {
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.FullChecked {
  width: 10px;
  height: 10px;

  border-radius: 2px;
  background-color: #e1e319;
}
.Not-checked {
  width: 10px;
  height: 10px;

  border-radius: 2px;
  border: solid 0.5px #707070;
}
.SaveModal {
  width: 71px;
  height: 32px;

  border: 0;
  border-radius: 5px;
  background-color: #0b5cab;
  font-family: Muli;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.exitModal {
  width: 71px;
  height: 32px;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  font-family: Muli;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: rgba(16, 22, 80, 0.7);
  background-color: #fff;
}
.RoleTitleInput {
  background-color: "#fff";
  border-radius: 5px;
  height: 40px;
}
.RoleDescInput {
  height: 108px;
  background-color: "#fff";
  border-radius: 5px;
}
