.title {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.28;
  color: #101650;
}

.monthYear {
  font-family: Muli;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #101650;
}
.day {
  font-family: Muli;
  font-size: 12px;
  color: #101650;
  font-weight: "bold";
}

.time {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.22;
  color: #101650;
}
.date {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
  position: sticky;
  top:0px;
  background-color: #fff;
  width:100%;
  height: 100%;
 
}

.timeSlot {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  color: #6b6b6b;
  border: solid 1px #bcbbbb;
  background-color: rgba(63, 156, 17, 0.4);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.ShipmentsContainer {
  background-color: rgba(86, 191, 35, 0.4);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ShipmentCardTitles {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  color: #101650;
}

.ShipmentCardDetails {
  font-family: Muli;
  font-size: 12px;
  color: #101650;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.noShipmentMessage {
  font-family: Muli;
  font-size: 12px;
  color: #101650;
  text-align: center;
}

.ShipmentFormTitle {
  font-family: Muli;
  font-size: 20px;
  color: #000;
  text-align: center;
}

.ShipmentFormFields {
  font-family: Muli;
  font-size: 12px;
  color: #303030;
}

.textField {
  height: 34px;
  border-radius: 5px;
  border: solid 1px rgba(206, 210, 225, 0.97);
  border: 0;
  font-family: Muli;
}
.Rectangle {
  border-radius: 5px;
  border: solid 1px rgba(206, 210, 225, 0.97);
  font-family: Muli;
  color: #0674b9;
  font-size: 12px;
  height: 34px;
  display: flex;
  padding: 0.4rem 0.4rem 0.4rem 0.6rem;
  overflow: auto;
}

.RectangleWithScroll {
  border-radius: 5px;
  border: solid 1px rgba(206, 210, 225, 0.97);
  font-family: Muli;
  color: #0674b9;
  font-size: 12px;
  padding: 0.5rem;
  height: 80px;
  overflow: auto;
}

.save-button {
  padding: 7px 22px 7px 22px;
  border-radius: 5px;
  background-color: #0674b9;
  font-family: Muli;
  font-size: 13px;
  text-align: center;
  color: #fff;
  border: 0;
}
.save-button:hover {
  cursor: pointer;
  background-color: #101650;
}
.save-button:disabled {
  background-color: #bcbcbc;
}
.save-button:disabled:hover {
  cursor: unset;
}

.cancel-button {
  padding: 7px 22px 7px 22px;
  border-radius: 5px;
  border: 0;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  line-height: 1.5;
  text-align: center;
  color: #0674b9;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.cancel-button:hover {
  background-color: #bcbcbc;
  cursor: pointer;
  color: #fff;
}

.back-button {
  width: 57px;
  border-radius: 5px;
  height: 30px;
  border: 0;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #444;
}
.back-button:hover {
  background-color: #bcbcbc;
  cursor: pointer;
  color: #fff;
}

.MenuItems {
  font-family: Muli;
  font-size: 10px;
  line-height: 1.6;
  text-align: left;
  color: #707070;
}
.MenuItems {
  font-family: Muli;
  font-size: 10px;
  line-height: 1.6;
  text-align: left;
  color: #707070;
}

.DeleteMsg {
  font-family: Muli;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #101650;
}

.successMsg {
  font-family: Muli;
  font-size: 12px;
  color: green;
}

.CardsTitle {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  color: #101650;
}

.DeliveryCode {
  color: #101650;
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
}

.CardDetailsBlue {
  color: #0674b9;
  font-family: Muli;
  font-size: 15px;
}
.Delivery-Note {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #101650;
}
.View-next {
  font-family: Muli;
  font-size: 18px;
  text-align: center;
  text-decoration: underline;
  color: #101650;
}
.View-next:hover {
  cursor: pointer;
  color: #0674b9;
}
.UploadPics {
  padding: 5px;
  border-radius: 5px;
  border: solid 1px #e9e7e7;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  line-height: 1.3;
  text-align: left;
  color: #707070;
}
.UploadPics:hover {
  cursor: pointer;
}
.ResTitle {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.MenuItemss {
  font-family: Muli;
  font-size: 10px;
  line-height: 1.3;
  text-align: left;
  color: #101650;
}
.Rectangle-1224 {
  padding: 10px;
  border-radius: 5px;
  border: 0;
  background-color: transparent;
  font-family: Muli;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #444;
}