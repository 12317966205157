.Rectangle779 {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-image: url("../Images/ProfileDetails/SaaSbackground.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  padding-bottom: 10%;
  min-height: 100vh;
}
