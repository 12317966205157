.newTeamMember {
  font-family: Muli;
  font-size: 20px;
  line-height: 1.5;
  text-align: left;
  color: #101650;
}

.Line-402 {
  height: 0;
  border: solid 1px #ececec;
  background-color: #ececec;
}

.TMDetails {
  font-family: Muli;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #101650;
}
.Line-403-Main {
  height: 0;
  background-color: #fdfa72;

  border: solid 0.8px #fdfa72;
}
.Line-403-Sub {
  height: 0;
  background-color: #0674b9;

  border: solid 0.8px #0674b9;
}

.field-label {
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #444;
}

.field-label2 {
  opacity: 0.5;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #444;
}

.Invite {
  width: 71px;
  height: 32px;

  border: 0;
  border-radius: 5px;
  background-color: #0b5cab;
  font-family: Muli;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Cancel {
  width: 71px;
  height: 32px;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  font-family: Muli;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: rgba(16, 22, 80, 0.7);
  background-color: #fff;
}

.boxStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: 30%;
  transform: translate(-50%, -50%);
  background-color: white;
  height: 68%;
  width: 58%;
  overflow: scroll;
}

@media only screen and (max-width: 900px) {
  .boxStyle {
    width: 85%;
  }
}
.FieldHeader {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.FieldData {
  padding-bottom: 10px;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  border-bottom: solid 1px #f1f1f1;
}
.GoBack {
  width: 70px;
  padding: 5px;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(16, 22, 80, 0.5);
  background-color: #fff;
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: center;
  color: #0674b9;
}
.GoBack:hover {
  cursor: pointer;
  background-color: #0674b9;

  color: #fff;
}
