.items {
  text-decoration: none;
  margin-left: 60px;
  border-bottom: 3px solid transparent;
  border-top: 3px solid transparent;
  font-family: Muli;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #101650;
}

.activeItems {
  text-decoration: none;
  margin-left: 60px;
  border-bottom: 3px solid transparent;
  border-top: 3px solid transparent;
  font-family: Muli;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #101650;
  font-weight: bold;
  border-top-color: #101650;
  background-color: rgba(139, 191, 226, 0.3);
  padding: 2px 5px 0 5px;
}


.navbar {
  overflow: hidden;
  
}

.navbar a {
  text-decoration: none;
  margin-left: 60px;
  border-bottom: 3px solid transparent;
  border-top: 3px solid transparent;
  font-family: Muli;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #101650;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 13px;  
  border: none;
  outline: none;
  color: white;
  padding: 20px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}
.navbar a:hover, .dropdown:hover .dropbtn {
  text-decoration: none;
  margin-left: 60px;
  border-bottom: 3px solid transparent;
  border-top: 3px solid transparent;
  font-family: Muli;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #101650;
  font-weight: bold;
  border-top-color: #101650;
  background-color: rgba(139, 191, 226, 0.3);
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  width: 160px;
  box-shadow: 0 3px 6px 0 #908C8C;
  border-radius: 5%;
}

.dropdown-content a {
  padding: 8px 20px;
  text-decoration: none;
  display: block;
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #0674b9;
}

.dropDownContent a{
  text-decoration: none;
  font-family: Muli;
  font-size: 13px;
  color: #0674b9;
}

.dropDownContent a:hover {
  font-weight: bold;

}

.dropdown-content a:hover {
  background-color: #e9e7e7;
}

.dropdown:hover .dropdown-content {
  display: block;
  margin-top: 2%;
}