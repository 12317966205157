.title {
  font-family: Muli;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.tabs {
  opacity: 0.58;
  font-family: Muli;
  font-size: 21px;
  line-height: 1.48;
  text-align: left;
  color: #0674b9;
}

.fields {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.addButtonSC {
  direction: flex;
  width: 53px;
  height: 23px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 #2da4d0;
  background-color: #fff;
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  color: #2da4d0;
  border: 0;
  align-items: center;
  justify-content: center;
}

.addButtonEmpty {
  direction: flex;
  width: 53px;
  height: 23px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 lightgray;
  background-color: #fff;
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  color: lightgray;
  border: 0;
  align-items: center;
  justify-content: center;
}

.addButtonMC {
  direction: flex;
  height: 23px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 #e1e319;
  background-color: #fff;
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  color: #e1e319;
  border: 0;
  align-items: center;
  justify-content: center;
}
.PhaseResponsibilityButtons {
  display: flex;
  width: 87.6px;
  height: 23px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px 0 #e1e319;
  background-color: #fff;
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
  color: #e1e319;
  border: 0;
  border-radius: 5px;
}

.SubResponsibilityButtons {
  display: flex;
  width: 87.6px;
  height: 23px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px 0 #0674b9;
  background-color: #fff;
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
  color: #0674b9;
  border: 0;
  border-radius: 5px;
}

.uploadButtonSC {
  padding: 5px;
  direction: flex;
  width: 63px;
  height: 23px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 #2da4d0;
  background-color: #fff;
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  color: #2da4d0;
  border: 0;
  align-items: center;
  justify-content: center;
}

.uploadButtonMC {
  padding: 5px;
  direction: flex;
  height: 23px;
  width: 63px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 #e1e319;
  background-color: #fff;
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  color: #e1e319;
  border: 0;
  align-items: center;
  justify-content: center;
}
