.newMC{
    font-family: Muli;
    font-size: 20px;
    line-height: 1.5;
    text-align: left;
    color: #181818;
}

.Line-402 {
  height: 0;
  border: solid 1px #ececec;
  background-color: #ececec;
}

  .MC-Information{
    font-family: Muli;
    font-size: 16px;
    line-height: 1.5;
    color: #181818;
    width: 176px;
  

  }
  
  .Line-403-Main {

    height: 0;
    background-color: #fdfa72;
  
    border: solid 0.8px #fdfa72;
  }
  .Line-403-Sub {

    height: 0;
    background-color: #0674b9;
  
    border: solid 0.8px #0674b9;
  }

  .field-label {
    
    font-family: Muli;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #444;
  }
  .Field-input {
    width:90%;
    
   
    border-radius: 5px;
    border: none ;
  }

  .Field-input2 {
    width:80%;
    
   
    border-radius: 5px;
    border: none ;
  }

  .field-label2 {
    opacity: 0.5;
    font-family: Muli;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #444;
  }

  .SaveModal {
    width: 71px;
    height: 32px;
  
    border: 0;
    border-radius: 5px;
    background-color: #0b5cab;
    font-family: Muli;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  .exitModal {
    width: 71px;
    height: 32px;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    font-family: Muli;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: rgba(16, 22, 80, 0.7);
    background-color: #fff;
  }