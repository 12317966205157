.sideBg {
}

.whyyy{
  padding-bottom: 5%;
}
@media only screen and (max-width: 1200px) {
  .whyyy{
    padding-bottom: 37%;
  }
  .sideBg {
    display: none;
  }
  .descriptionTitle {
    font-size: 4vw !important;
  }
  .description2 {
    font-size: 4vw !important;
    margin-top: 5%;
  }
  .description3 {
    font-size: 3.9vw !important;
  }
  .descriptionBg{
      margin-top: 5% !important;
      padding: 2px !important;
  }
}

.infos {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #101650;
  margin-left: 10%;
}
.descriptionTitle {
  font-family: Muli;
  font-size: 25px;
  font-weight: bold;
  color: #101650;
}
.description2 {
  font-family: Muli;
  font-size: 20px;
  line-height: 1.3;
  justify-content: center;
  color: rgba(16, 22, 80, 0.7);
}
.descriptionBg {
  max-width: fit-content;
  padding: 7px;
  background-color: #E1E319;
  margin-top: 1%;
}
.description3 {
  font-family: Muli;
  font-size: 23px;
  font-weight: bold;
  text-align: left;
  color: #fff;
}

.desc {
  padding-left:5%;
  padding-top:4%;
}

@media only screen and (max-width: 730px) {
  .desc {
    padding-top: 5%;
  }
}

@media only screen and (max-width: 500px) {
  .desc {
    padding: 15% 0 12% 0;
  }

  .descriptionBg {
    max-width:350px;
  }
}