.Phases-Card {
  border: solid 1px #f1f1f1;
  background-color: #fff;
}

.Card-Name {
  font-family: Muli;
  font-size: 12px;

  text-align: left;
  color: #101650;
}
.plus {
  -webkit-text-stroke: 1px #0674b9;
  font-family: Muli;
  font-size: 12px;
  text-align: left;
  color: #0674b9;
}
