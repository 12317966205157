.back {
  width: 57px;
  height: 30px;
  border-radius: 5px;
  border: solid 1px #e9e7e7;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  line-height: 1.5;
  text-align: center;
  color: #444;
}

.tableTitle {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.Rows {
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 10px 2px 10px 20px;
}

.RowsTitles {
  font-family: Muli;

  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.RoleGroup {
  font-family: Muli;

  font-weight: 550;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.94;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.Description {
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #0674b9;
}

.PermissionButton {
  width: 135px;
  height: 32px;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  font-family: Muli;
  font-size: 13px;
  line-height: 1.47;
  text-align: center;
  color: rgba(16, 22, 80, 0.7);
  background-color: #fff;
}

@media only screen and (max-width: 800px) {
  .PermissionButton {
    width: 100px;
    height: 32px;

    font-size: 10px;
  }
  .RoleGroup {
    font-size: 15px;
  }
}
.AddRoleBox {
  border-radius: 8px;
  box-shadow: 0 12px 6px 0 #806d6d;
  background-color: #cae0ef;
  padding-bottom: 40px;
}
.RoleTitleInput {
  width: 75%;
  background-color: "#fff";
  border-radius: 5px;
  height: 40px;
}
.RoleDescInput {
  width: 80%;
  height: 108px;
  background-color: "#fff";
  border-radius: 5px;
}
.EditPermission {
  padding: 8px;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  font-family: Muli;
  font-size: 13px;
  text-align: left;
  color: #0674b9;
}
.EditPermission:disabled {
  color: #806d6d;
}
.AddPermission {
  width: fit-content;
  padding: 8px;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(16, 22, 80, 0.5);
  background-color: #fff;
  font-family: Muli;
  font-size: 13px;
  text-align: center;
  color: #0674b9;
}
.AddPermission:disabled {
  color: #806d6d;
}
.ActiveTab {
  padding: 1px 15px 4px 17px;
  border-radius: 19px;
  box-shadow: 0 3px 6px 0 #101650;
  border: solid 1px #101650;
  background-color: #fff;
  font-family: Muli;
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  color: #0674b9;
}
.NotActiveTab {
  font-family: Muli;
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  color: #101650;
}
.NotActiveTab:hover {
  cursor: pointer;
  padding: 1px 15px 4px 17px;
  border-radius: 19px;
  box-shadow: 0 3px 6px 0 #101650;
  border: solid 1px #101650;
  background-color: #fff;
  font-family: Muli;
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  color: #0674b9;
}
