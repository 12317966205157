.title{
    font-family: Muli;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    color: #101650;
}

.Day{
    font-family: Muli;
    font-size: 12px;
    text-align: left;
    color: #101650;
    font-weight: "bold";
}

.Date{
    font-family: Muli;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #101650;
  
}

.monthyear{
    font-family: Muli;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #101650;
}
.code{
    font-family: Muli;
    font-size: 9px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
}

.description{
    font-family: Muli;
    font-size: 9px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
}

.status{
    font-family: Muli;
    font-size: 9px;
    font-weight: bold;
    font-stretch: normal;
    font-style: italic;
    letter-spacing: normal;
    text-align: left;
}

.weight{
    font-family: Muli;
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
}

.upperData{
    font-family: "Muli";
    font-size: 9px;
    text-align: left;
    color: #0674b9;
    font-weight: "bold";
}

.suggestedDate{
  font-family: Muli;
  font-size: 9px;
  font-weight: bold;
  text-align: left;

}
.dragged{
    opacity: 0.3;
}

.back-button {
    width: 57px;
    border-radius: 5px;
    height: 30px;
    border: 0;
    background-color: #fff;
    font-family: Muli;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #444;
  }
  .back-button:hover {
    background-color: #bcbcbc;
    cursor: pointer;
    color: #fff;
  }
  
  .textField {
    height: 15px;
    border-radius: 5px;
    border: solid 1px rgba(206, 210, 225, 0.97);
    border: 0;
    font-family: Muli;
    font-size: 9px;
    text-align: right;
  }

  .save-button {
    border-radius: 5px;
    background-color: #0674b9;
    font-family: Muli;
    font-size: 9px;
    text-align: center;
    color: #fff;
    border: 0;
  }
  .save-button:hover {
    cursor: pointer;
    background-color: #101650;
  }