.Go-Back {
  padding: 5px 8px 5px 8px;
  font-family: Muli;
  font-size: 10px;
  line-height: 1.5;
  text-align: center;
  color: #444;
  border-radius: 5px;
  border: solid 1px #e9e7e7;
  background-color: #fff;
}

.Go-Back:hover {
  cursor: pointer;
  background-color: #444;
  color: #fff;
}

.Path-3900 {
  padding: 13px 8.7px 14.1px 8.7px;
  border: solid 1px #0674b9;
  background-color: #fff;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: center;
  color: #0674b9;
  border-radius: 10px;
}

.Path-3900:hover {
  cursor: pointer;
}

.Path-3900:disabled {
  cursor: unset;
}

.Replan {
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: center;
  color: #0674b9;
  text-decoration: underline;
}

.Path-3899 {
  width: 73px;
  height: 55x;
  border: solid 1px #2da4d0;
  background-color: rgba(95, 186, 71, 0.3);
  font-family: Muli;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  color: #101650;
  border-radius: 7px;
}

.Rectangle-4046 {
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(45, 164, 208, 0.3);
  background-color: #fff;
}

.Optimization-Parameters {
  font-family: Muli;
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  color: #101650;
}

.MachineType {
  font-family: Muli;
  font-size: 15px;
  text-align: left;
  color: #0674b9;
}

.Opt-params {
  font-family: Muli;
  font-size: 13px;
  font-style: italic;
  text-align: left;
  color: #0674b9;
}

.Enabled {
  font-family: Muli;
  font-size: 10px;
  font-weight: 300;
  text-align: left;
  color: #707070;
}

.cancelbut {
  padding: 7px 14px 6px 15px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  font-family: Muli;
  font-size: 13px;
  line-height: 1.46;
  text-align: center;
  color: #0674b9;
  border: 0;
}

.cancelbut:hover {
  cursor: pointer;
  background-color: #0674b9;
  cursor: pointer;
  color: #fff;
}

.cancelbut:disabled {
  background-color: #bcbcbc;
  color: #fff;
}

.SaveBut {
  padding: 7px 22px 6px 22px;
  border-radius: 5px;
  background-color: #0674b9;
  font-family: Muli;
  font-size: 13px;
  text-align: center;
  color: #fff;
  border: 0;
}

.SaveBut:hover {
  cursor: pointer;
  background-color: #101650;
}

.SaveBut:disabled {
  background-color: #bcbcbc;
}

.SaveBut:disabled:hover {
  background-color: #707070;
  cursor: unset;
}

.macchine-info {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: #101650;
}

.Rectangle-4027 {
  width: 140px;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(45, 164, 208, 0.3);
  background-color: #fff;
}

.diaRef {
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
  color: #101650;
}

.diaName {
  font-size: 18px;
  font-weight: bold;
  font-family: Muli;
  text-align: left;
  color: #101650;
}

.infoMachines {
  font-family: Muli;
  font-size: 11px;
  text-align: left;
  color: rgba(16, 22, 80, 0.7);
  line-height: 1.45;
}

.totalstyles {
  font-family: Muli;
  font-size: 10px;
  text-align: left;
  color: #101650;
  line-height: 1.45;
}

.percentageStyles {
  font-family: Muli;
  font-size: 10px;
  font-weight: bold;
  text-align: left;
  color: #2da4d0;
  line-height: 1.45;
}

.Line-859 {
  height: 0;
  border: solid 1px #e9e7e7;
  background-color: #e9e7e7;
}

.materialtype {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: rgba(16, 22, 80, 0.7);
}

.materialWeight {
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
  color: #707070;
}

.SalesTags {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: #2da4d0;
}

.RemnantAndScrap {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: rgba(95, 186, 71, 0.7);
}

.Rectangle-4041 {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  box-shadow: 0 3px 6px 0 rgba(6, 116, 185, 0.3);
  background-color: rgba(45, 164, 208, 0.5);
}

.Rectangle-4041:hover {
  cursor: pointer;
  background-color: rgba(19, 83, 106, 0.5);
}

.LineInfo {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.17;
  text-align: left;
  color: #444;
}

.LineValues {
  font-weight: normal;
  font-family: Muli;
  font-size: 12px;
  line-height: 1.17;
  text-align: left;
  color: #0674b9;
}