.title {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.back-button {
  width: 57px;
  border-radius: 5px;
  height: 30px;
  border: 0;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #444;
}
.back-button:hover {
  background-color: #bcbcbc;
  cursor: pointer;
  color: #fff;
}

.viewText {
  font-family: Muli;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #101650;
}

.viewTextBlue {
  font-family: Muli;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #0674b9;
}

.fieldsTitle {
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #bcbcbc;
}

.textField {
  height: 34px;
  border-radius: 5px;
  border: solid 1px rgba(206, 210, 225, 0.97);
  border: 0;
  font-family: Muli;
}
.fieldsTitleCurrency {
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.textFieldCurrency {
  height: 29px;
  font-size: 10px;
  border-radius: 5px;
  border: solid 1px rgba(206, 210, 225, 0.97);
  border: 0;
  font-family: Muli;
}

.Rectangle {
  border-radius: 5px;
  border: solid 1px rgba(206, 210, 225, 0.97);
  font-family: Muli;
  color: #0674b9;
  font-size: 12px;
  height: 34px;
  display: flex;
  padding: 0.4rem 0.4rem 0.4rem 0.6rem;
  overflow: auto;
}
.Rectangle-1224 {
  padding: 10px;
  border-radius: 5px;
  border: 0;
  background-color: transparent;
  font-family: Muli;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #444;
}

.RectangleWithScroll {
  border-radius: 5px;
  border: solid 1px rgba(206, 210, 225, 0.97);
  font-family: Muli;
  color: #0674b9;
  font-size: 12px;
  padding: 0.5rem;
  overflow: auto;

}

.blueText {
  font-family: Muli;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #0674b9;
}

.greenText {
  font-family: Muli;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: green;
}
.itemsTitle {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.categorytitle {
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #707070;
}
.CheckedBox {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background-color: #e1e319;
}
.notCheckedBox {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: solid 1px #707070;
  background-color: #fff;
}
.notCheckedBox:hover {
  background-color: #e1e319;
}

.save-button {
  padding: 7px 22px 6px 22px;
  border-radius: 5px;
  background-color: #0674b9;
  font-family: Muli;
  font-size: 13px;
  text-align: center;
  color: #fff;
  border: 0;
}
.save-button:hover {
  cursor: pointer;
  background-color: #101650;
}
.save-button:disabled {
  background-color: #bcbcbc;
}
.save-button:disabled:hover {
  cursor: unset;
}

.cancel-button {
  width: 57px;
  border-radius: 5px;
  height: 30px;
  border: 0;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #0674b9;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.cancel-button:hover {
  background-color: #bcbcbc;
  cursor: pointer;
  color: #fff;
}

.greyText {
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #707070;
}

.blueText {
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #2da4d0;
}

.errorMsg {
  color: #d32f2f;
  font-family: Muli;
  font-size: 13px;
}

.darkTitle {
  font-family: Muli;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.EmptyMessage {
  height: 100%;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: rgba(6, 116, 185, 0.5);
}

.back-button-whiteBg {
  width: 57px;
  border-radius: 5px;
  height: 30px;
  border: 0;
  background-color: #101650;
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.back-button-whiteBg:hover {
  background-color: #bcbcbc;
  cursor: pointer;
  color: #fff;
}

.print-button {
  width: 57px;
  border-radius: 5px;
  height: 30px;
  border: 0;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #2da4d0;
  box-shadow: 0 3px 6px 0 #2da4d0;
}
.print-button:hover {
  background-color: #2da4d0;
  cursor: pointer;
  color: #fff;
}

.Production-Label {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.44;
  text-align: left;
  color: #101650;
}
.cancelbut {
  padding: 7px 14px 6px 15px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  font-family: Muli;
  font-size: 13px;
  line-height: 1.46;
  text-align: center;
  color: #0674b9;
  border: 0;
}

.cancelbut:hover {
  cursor: pointer;
}

.cancelbut:disabled {
  background-color: #bcbcbc;
  color: #fff;
}
.Cant-Print-Label {
  font-family: Muli;
  font-size: 18px;
  line-height: 1.44;
  text-align: center;
  color: #444;
}
.No-label-templates {
  font-family: Muli;
  font-size: 13px;
  line-height: 1.46;
  text-align: center;
  color: #444;
}