.Working-hours {
  font-family: Muli;
  font-size: 22px;
  line-height: 1.5;
  text-align: left;
  color: #101650;
}
.Working-titles {
  font-family: Muli;
  font-size: 18px;
  line-height: 1.5;
  text-align: left;
  color: #101650;
}
.To {
  font-family: Muli;
  font-size: 22px;
  line-height: 1.5;
  text-align: center;
  color: #101650;
}
.Checked-box {
  width: 15px;
  height: 15px;
  margin: 0 0 25px;
  background-color: #e1e319;
}
.Checked-box:hover {
  cursor: pointer;
}
.not-checked-box {
  width: 15px;
  height: 15px;
  margin: 10px 0 25px;
  border: solid 1px #707070;
  background-color: #fff;
}
.not-checked-box:hover {
  cursor: pointer;
}
.Days {
  font-family: Muli;
  font-size: 18px;
  text-align: left;
  color: #0674b9;
}
.date {
  font-family: Muli;
  color: #707070;
}
.successfully-saved {
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: rgba(16, 22, 80, 0.7);
}
.SaveButton {
  padding: 5px;
  width: 60px;
  border-radius: 5px;
  background-color: #0b5cab;
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: #fff;
}
.SaveButton:hover {
  cursor: pointer;
  background-color: #fff;
  color: #0674b9;
  border: solid 1px #0674b9;
}
.BackButton {
  padding: 5px;
  width: 60px;
  border-radius: 5px;
  background-color: #fff;
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: #707070;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}
.BackButton:hover {
  cursor: pointer;
  background-color: #0674b9;
  color: #fff;
}
