.homeTitle {
    width: 80%;
    font-family: Muli;
    font-size: 48px;
    font-weight: bold;
    text-align: left;
    color: #101650;
}

.mobileHomeImg {
    width: 100%;
}

.boxDemo {
    width:75%;
    border-radius: 12px;
    background-color: white;
    padding-bottom: 10%;
}

.homePage {
    background-color: white;
}

.homeDescription {
    margin-top: 0%;
    margin-bottom: 0%;
    width: 69%;
    font-family: Muli;
    font-size: 18px;
    line-height: 1.44;
    text-align: left;
    color: #101650;
}

.ellipseMC {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-size: 21px;
  font-weight: bold;
  line-height: 1.52;
  color: #fff;
  background-color: rgba(225, 227, 25, 0.7);
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.Main-text {
  width: 60%;
  font-family: Muli;
  font-size: 39px;
  font-weight: bold;
  line-height: 1;
  text-align: left;
  color: #101650;
}

.ChooseMe {
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.3;
  text-align: left;
  color: #101650;
}

.chooseRadio {
    width: 16px;
    height: 16px;
}

.ellipseSC {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-size: 21px;
  font-weight: bold;
  line-height: 1.52;
  color: #fff;
  background-color: rgba(45, 164, 208, 0.5);
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.Profile-desc {
  width: 85%;
  font-family: Muli;
  font-size: 18px;
  line-height: 1.44;
  text-align: left;
  color: #101650;
}

.manageCheck {
  font-family: Muli;
  font-size: 15px;
  line-height: 1;
  text-align: left;
  color: #181818;
}

.manageCheck1 {
    font-family: Muli;
    font-size: 15px;
    line-height: 2;
    text-align: left;
    color: #181818;
}

.homeButton {
  width: 30%;
  border-radius: 7px;
  color: #fff;
}

.getStarted {
    font-family: Muli;
    font-size: 20px;
    font-weight: bold;
    line-height: 2.5;
    text-align: left;
    color: #101650;   
}

.Choose-your-Profile {
    font-family: Muli;
    font-size: 48px;
    font-weight: bold;
    line-height: 1.04;
    text-align: left;
    color: #101650;
}

@media only screen and (max-width: 1200px) {

    .homeTitle {
        margin-top: 10%;
        font-family: Muli;
        font-size: 32px;
        font-weight: bold;
        line-height: 1.04;
        text-align: left;
        color: #101650;
    }

    .Choose-your-Profile {
        font-family: Muli;
        font-size: 32px;
    }
    
    .homeDescription {
        width: 86%;
        font-family: Muli;
        font-size: 15px;
        line-height: 1.44;
        text-align: left;
        color: #101650;
    }

    .Profile-desc {
        font-family: Muli;
        font-size: 15px;
        line-height: 1.44;
        text-align: left;
        color: #101650;
      }
    
    .homeButton {
      width: 36%;
      border-radius: 7px;
      text-transform: none;
      font-family: Muli;
      font-size: 10px;
      font-weight: bold;
      line-height: 1.5;
      text-align: left;
      color: #fff;
    }

    .Main-text {
        font-size: 30px;
    }

    .ChooseMe {
        font-size: 10px;
    }

    .chooseRadio {
        width: 10px;
        height: 10px;
    }

    .manageCheck {
        font-size: 10px;
    }

    .manageCheck1 {
        font-size: 10px;
      }
}

.choosing {
    margin-left: 0%;
}

.profiles {
    margin-right:14%;
    margin-left:5%;
}

@media only screen and (max-width: 1300px) {
    .profiles {
        margin-right:14%;
        margin-left:8%;
    }

    .Choose-your-Profile {
        font-family: Muli;
        font-size: 32px;
    }

}

@media only screen and (max-width: 1012px) {

    .choosing {
        margin-left: 2%;
    }

    .getStarted {
        font-family: Muli;
        font-size: 18px;
        font-weight: bold;
        line-height: 2.5;
        text-align: left;
        color: #101650;   
    }

    .homeTitle {
        margin-top: 10%;
        font-family: Muli;
        font-size: 32px;
        font-weight: bold;
        line-height: 1.3;
        text-align: left;
        color: #101650;
    }

    .Choose-your-Profile {
        font-family: Muli;
        font-size: 32px;
    }
    
    .homeDescription {
        width: 86%;
        font-family: Muli;
        font-size: 15px;
        line-height: 1.44;
        text-align: left;
        color: #101650;
    }

    .Profile-desc {
        font-family: Muli;
        font-size: 15px;
        line-height: 1.44;
        text-align: left;
        color: #101650;
      }
    
    .homeButton {
      width: 46%;
      border-radius: 7px;
      text-transform: none;
      font-family: Muli;
      font-size: 10px;
      font-weight: bold;
      line-height: 1.5;
      text-align: left;
      color: #fff;
    }
}
.show1 {
    margin-left:8%;
    flex-direction:'row';
}

.homeImg {
    width: 100%;
}

.writing {
    width:57%;
    gap: 5%;
}

@media only screen and (max-width: 935px) {

    .Choose-your-Profile {
        font-family: Muli;
        font-size: 26px;
    }   
    
}

@media only screen and (max-width: 899px) {

    .ellipseMC {
        font-size: 15px;
        width: 36px;
        height: 36px;
    }

    .ellipseSC {
        font-size: 15px;
        width: 36px;
        height: 36px;
    }

    .manageCheck1 {
        font-family: Muli;
        font-size: 10px;
        line-height: 1 ;
        text-align: left;
        color: #181818;
    }

    .homePage {
        background-image: url('../Images/home/bgim.png');
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 5%;
    }

    .getStarted {
        font-family: Muli;
        font-size: 10px;
        font-weight: bold;
        line-height: 2.5;
        text-align: left;
        color: #101650;   
    }

    .Choose-your-Profile {
        font-family: Muli;
        font-size: 25px;
        width: 70%;
    }

    .homeDescription {
        margin-top: 2%;
        margin-bottom: 3%;
        font-size: 14px;
    }

    .Profile-desc {
        font-family: Muli;
        font-size: 14px;
        line-height: 1.44;
        text-align: left;
        color: #101650;
        width: 120%;
      }

    .Main-text {
        width: 60%;
        font-family: Muli;
        font-size: 20px;
        font-weight: bold;
        line-height: 0.9;
        text-align: left;
        color: #101650;
    }

    .ChooseMe {
        font-size: 10px;
    }

}

/* @media only screen and (max-width: 730px) {
    .show1 {
      margin-left: 5%;  
      flex-direction: column;
      gap:20px;
    }

    .writing {
        width: 100%;
    }

    .homeImg {
        width: 165%;
    }
}

@media only screen and (max-width: 550px) {
    .homeButton {
        width: 45%;
    }
} */