.back-button {
  width: 57px;
  border-radius: 5px;
  height: 30px;
  border: 0;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #444;
}
.back-button:hover {
  background-color: #bcbcbc;
  cursor: pointer;
  color: #fff;
}
.status {
  font-family: Muli;
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: #101650;
}
.ColumnsTitles {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: #101650;
}
.PhaseName {
  font-family: Muli;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #101650;
}

.Dates {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: #0674b9;
  overflow: auto;
}
.Dates-surpasses {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: rgba(255, 0, 0, 0.48);
  overflow: auto;
}
.No-Status {
  font-family: Muli;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #bcbcbc;
}
.Approved {
  font-family: Muli;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #707070;
}
.Line-529 {
  background-color: #e9e7e7;
  height: 1px;
  border: solid 1px #e9e7e7;
}
.plus {
  -webkit-text-stroke: 1px #0674b9;
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: #0674b9;
}
.openedPhases {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: #101650;
}
.Subproject-Name {
  font-family: Muli;
  font-size: 16px;

  line-height: 1.5;
  text-align: left;
  color: rgba(6, 116, 185, 0.5);
}
.Sub-subproject-opened {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: #0674b9;
}
.Milestone-Action {
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: center;
  color: #101650;
}
.Rectangle-1449 {
  width: 600px !important;
}

.messageAction {
  font-family: Muli;
  font-size: 13px;
  line-height: 1.46;
  text-align: left;
  color: #101650;
}
.Actions-Modal {
  font-family: Muli;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.46;
  text-align: left;
  color: #101650;
}
.Save {
  font-family: Muli;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  color: #101650;
}
