.page-title {
  font-family: Muli;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.Fields-Titles {
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: rgba(16, 22, 80, 0.7);
}
.Field-inputs {
  width: 95%;
  height: 40px;

  border-radius: 5px;
  border: 0;
  border: solid 1px #f2f2f2;
}
.button-save {
  width: 95%;
  height: 40px;
  border: 0;
  border-radius: 5px;
  background-color: #0b5cab;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.button-save:disabled {
  opacity: 0.7;
  background-color: #f2f2f2;
 cursor: none;
  /* color: #0b5cab; */
}
