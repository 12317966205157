.Table-Title {
  font-family: Muli;
  font-size: 18px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.back-button {
  padding: 7px;
  border-radius: 5px;

  border: 0;
  background-color: #fff;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #444;
}
.back-button:hover {
  background-color: #bcbcbc;
  cursor: pointer;
  color: #fff;
}
.AddButton {
  height: 30px;
  padding: 7px;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(16, 22, 80, 0.5);
  background-color: #fff;
  font-family: Muli;
  font-size: 13px;
  text-align: left;
  color: #0674b9;
  margin-left: 1%;
}
.AddButton:hover {
  background-color: #0674b9;
  cursor: pointer;
  color: #fff;
}
.AddButton:disabled {
  background-color: #bcbcbc;

  color: #fff;
}
.AddButton:disabled:hover {
  background-color: #bcbcbc;
  cursor: unset;
  color: #fff;
}
.filterDrop {
  width: 131.1px;

  border-radius: 5px;
  border: solid 1px #e9e7e7;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #444;
}
.TableHeader {
  background-color: rgb(172 201 224);
}
/* ::-webkit-scrollbar {
  color: #0674b9;
} */
.EmptyMessage {
  height: 100%;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: rgba(6, 116, 185, 0.5);
}
.Rectangle-1224 {
  padding: 10px;
  border-radius: 5px;
  border: 0;
  background-color: transparent;
  font-family: Muli;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #444;
}
.RfiButtons {
  height: 30px;
  padding: 7px;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(16, 22, 80, 0.5);
  background-color: #fff;
  font-family: Muli;
  font-size: 13px;
  text-align: left;
  color: #0674b9;
  margin-left: 1%;
}
.RfiButtons:hover {
  background-color: #0674b9;
  cursor: pointer;
  color: #fff;
}
.RfiButtons:disabled {
  background-color: #bcbcbc;
  color: #fff;
}
.RfiButtons:disabled:hover {
  background-color: #bcbcbc;
  cursor: not-allowed;
}
