.Production-Label {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.44;
  text-align: left;
  color: #101650;
}

.LabelTemp {
  padding: 5px 8px 5px 8px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(16, 22, 80, 0.5);
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: #2da4d0;
  border: 0;
}

.LabelTemp:hover {
  cursor: pointer;
  background-color: #2da4d0;
  color: #fff;
}

.LabelTemp:disabled {
  background-color: beige;
  color: #fff;
}

.LabelTemp:disabled:hover {
  background-color: beige;
  color: #fff;
  cursor: unset;
}

.Go-Back {
  padding: 5px 8px 5px 8px;
  font-family: Muli;
  font-size: 10px;
  line-height: 1.5;
  text-align: center;
  color: #444;
  border-radius: 5px;
  border: solid 1px #e9e7e7;
  background-color: #fff;
}

.Go-Back:hover {
  cursor: pointer;
  background-color: #444;
  color: #fff;
}

.chooseLabel {
  font-family: Muli;
  font-size: 23px;
  line-height: 1.48;
  text-align: center;
  color: #101650;
}

.chooseLabelMain {
  font-family: Muli;
  font-size: 28px;
  line-height: 1.48;
  text-align: center;
  color: #101650;
}

.Upgrade-subscription-required {
  font-family: Muli;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.Rectangle-1231 {
  padding: 5px;
  border-radius: 5px;
  background-color: #101650;
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  border: 0;
  color: #fff;
}

.Rectangle-1231:hover {
  cursor: pointer;
}

.controlSystem {
  width: 223px !important;
  padding: 10px 23.5px 10px 20px;
  border: solid 0.1px #fcf9f9;
  background-color: #fcf9f9;
}

.Choose-options {
  font-family: Muli;
  font-size: 11px;
  line-height: 1.45;
  text-align: left;
  color: #0674b9;
}

.Set-as-default {
  font-family: Muli;
  font-size: 10px;
  line-height: 1.5;
  text-align: center;
  color: #707070;
}

.DefaultLabel {
  width: 19px;
  height: 19px;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(206, 210, 225, 0.97);
  border: solid 1px var(--pinkish-grey);
  background-color: #fff;
}

.DefaultLabel:hover {
  cursor: pointer;
  background-color: #2da4d0;
}

.DefaultLabelChecked {
  width: 19px;
  height: 19px;
  border-radius: 3px;
  border: solid 1px #707070;
  background-color: #2da4d0;
}

.DefaultLabelChecked:hover {
  cursor: pointer;
}

.LabelType {
  padding: 2px 2.9px 1.9px 12px;
  border-radius: 2px;
  background-color: rgba(45, 164, 208, 0.3);
  font-family: Muli;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.45;
  text-align: left;
  color: #101650;
}

.or {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  text-align: center;
  color: #707070;
}

.CustomPrinter {
  padding: 4px 4.5px 4px 5px;
  border-radius: 3px;
  border: solid 1px #bcbcbc;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  line-height: 1.5;
  text-align: center;
  color: #707070;
}

.CustomPrinter:hover {
  box-shadow: 0 3px 6px 0 #0674b9;
  border: solid 1px #0674b9;
  cursor: pointer;
}

.CustomPrinterActivated {
  padding: 4px 4.5px 4px 5px;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 #0674b9;
  border: solid 1px #0674b9;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  line-height: 1.5;
  text-align: center;
  color: #0674b9;
}

.CustomPrinterActivated:hover {
  cursor: pointer;
}

.RawsField {
  width: 179px;
  padding-left: 10px;
  border-radius: 2px;
  background-color: rgba(206, 210, 225, 0.97);
  font-family: Muli;
  font-size: 13px;
  text-align: left;
  color: #101650;
}
.RawsFieldDN {
  width: 179px;
  padding-left: 10px;
  border-radius: 2px;
  background-color: rgba(45, 164, 208, 0.3);
  font-family: Muli;
  font-size: 11px;
  text-align: left;
  color: #101650;
}
.catTitle {
  font-family: Muli;
  font-size: 11px;
  line-height: 1.27;
  text-align: left;
  color: #101650;
}
.cancelbut {
  padding: 7px 14px 6px 15px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  font-family: Muli;
  font-size: 13px;
  line-height: 1.46;
  text-align: center;
  color: #0674b9;
  border: 0;
}

.cancelbut:hover {
  cursor: pointer;
}

.cancelbut:disabled {
  background-color: #bcbcbc;
  color: #fff;
}

.SaveBut {
  padding: 7px 22px 6px 22px;
  border-radius: 5px;
  background-color: #0674b9;
  font-family: Muli;
  font-size: 13px;
  text-align: center;
  color: #fff;
  border: 0;
}

.SaveBut:hover {
  cursor: pointer;
  background-color: #101650;
}

.SaveBut:disabled {
  background-color: #bcbcbc;
}

.SaveBut:disabled:hover {
  background-color: #707070;
  cursor: unset;
}

.tagForm {
  background-color: #fff;
  overflow: hidden;
  border: solid 2px #bcbcbc;
  border-style: dashed;
}
.tagForm2 {
  background-color: #fff;
  overflow: hidden;
 

}

.FieldTitle {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: #101650;
}

.FieldData {
  font-family: Muli;
  font-size: 11px;
  line-height: 1.45;
  text-align: left;
  color: #bcbcbc;
}

.FieldDataPrinted {
  font-family: Muli;
  font-size: 11px;
  line-height: 1.45;
  text-align: left;
  color: #675c5c;
}

.Type {
  font-family: Muli;
  font-size: 15px;
  line-height: 1.47;
  text-align: left;
  color: #101650;
}

.Rectangle-1464 {
  width: fit-content;
  border-radius: 5px;
  border: solid 1px #f3f3f3;
  background-color: #fff;
  padding-left: 18px;
}

.Rectangle-60 {
  padding: 5px 11px;
  border-radius: 5px;
  border: solid 1px #e9e7e7;
  background-color: #fff;
  color: #0674b9;
}

.Rectangle-60:hover {
  cursor: pointer;
}
.Project-Name {
  width: fit-content;
  font-family: Muli;
  font-size: 15px;
  line-height: 1.47;
  text-align: left;
  color: #707070;
}
.Cant-Print-Label {
  font-family: Muli;
  font-size: 18px;
  line-height: 1.44;
  text-align: center;
  color: #444;
}
.No-label-templates {
  font-family: Muli;
  font-size: 13px;
  line-height: 1.46;
  text-align: center;
  color: #444;
}
.EmptyMessage {
  height: 100%;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: rgba(6, 116, 185, 0.5);
}
.A4Su {
  width: 43.5px;
  height: 19px;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 #0674b9;
  border: solid 1px #0674b9;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
  color: #0674b9;
}
