.Go-Back {
  padding: 5px 8px 5px 8px;
  font-family: Muli;
  font-size: 10px;
  line-height: 1.5;
  text-align: center;
  color: #444;
  border-radius: 5px;
  border: solid 1px #e9e7e7;
  background-color: #fff;
}

.Go-Back:hover {
  cursor: pointer;
  background-color: #444;
  color: #fff;
}
.Inventory-Tabs {
  font-family: Muli;
  font-weight: bold;
  text-align: center;
  color: rgba(6, 116, 185, 0.5);
  border-bottom: solid 1px #e1e319;
}
.ActiveInventoryTab {
  border: solid 1px #e1e319;
  background-color: #fff;
  font-family: Muli;
  font-size: 21px;
  font-weight: bold;
  text-align: center;
  color: #101650;
  border-bottom: 0;
}
.FormDesign {
  border: solid 1px #e1e319;
  background-color: #fff;
  border-top: 0px;
  border-left: solid 5px #e1e319;
}
.ActiveInventoryTabCategory {
  border: solid 1px #e1e319;
  background-color: #fff;
  font-family: Muli;
  font-weight: bold;
  text-align: center;
  color: #101650;
  border-bottom: 0;
  border-left: solid 5px #e1e319;
}
.Lfields {
  font-family: Muli;
  font-size: 9px;
  text-align: left;
  color: #bcbcbc;
  padding-left: 10px;
}
.Rectangle-3005 {
  width: 70px;
  height: 28px;
  border-radius: 5px;
  padding: 5px 0px 0px 10px;
  border: solid 1px rgba(206, 210, 225, 0.97);
  font-family: Muli;
  font-size: 12px;
  text-align: left;
  color: #707070;
}
.TableLabels {
  font-family: Muli;
  font-size: 12px;
  text-align: left;
  color: #707070;
  border-right: solid 1px #bcbcbc;
  border-bottom: solid 1px #bcbcbc;
}
.notCheckedBox {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: solid 1px #707070;
  background-color: #fff;
}
.notCheckedBox:hover {
  cursor: pointer;
  background-color: #e1e319;
  border: solid 1px #e1e319;
}
.CheckedBox {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background-color: #e1e319;
}
.CheckedBox:hover {
  cursor: pointer;
}
.Rectangle-3780 {
  width: 54px;
  height: 54px;
  border: solid 1px rgba(206, 210, 225, 0.97);
  background-color: #fff;
}
.cancelbut {
  padding: 7px 14px 6px 15px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  font-family: Muli;
  font-size: 13px;
  line-height: 1.46;
  text-align: center;
  color: #0674b9;
  border: 0;
}
.cancelbut:hover {
  cursor: pointer;
  background-color: #0674b9;
  cursor: pointer;
  color: #fff;
}
.cancelbut:disabled {
  background-color: #bcbcbc;
  color: #fff;
}
.SaveBut {
  padding: 7px 22px 6px 22px;
  border-radius: 5px;
  background-color: #0674b9;
  font-family: Muli;
  font-size: 13px;
  text-align: center;
  color: #fff;
  border: 0;
}
.SaveBut:hover {
  cursor: pointer;
  background-color: #101650;
}
.SaveBut:disabled {
  background-color: #bcbcbc;
}
.SaveBut:disabled:hover {
  background-color: #707070;
  cursor: unset;
}
.Generate-Reference-Group {
  padding: 5px;
  font-family: Muli;
  font-size: 11px;
  line-height: 1.45;
  text-align: left;
  color: #0674b9;
  background-color: #fff;
  border: solid 1px #0674b9;
}
.Generate-Reference-Group:hover {
  cursor: pointer;
  background-color: #0674b9;
  color: #fff;
}
.Ref-Family-Group {
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
  color: #707070;
}
.Rectangle-3722 {
  max-height: 28px;
  padding: 5px;
  border-radius: 5px;
  border: solid 1px rgba(206, 210, 225, 0.97);
  font-family: Muli;
  font-size: 12px;
  text-align: left;
  color: #0674b9;
}
.Item-Category {
  font-family: Muli;
  font-size: 12px;
  text-align: left;
  color: #707070;
}
.TableTitle {
  font-family: Muli;
  font-size: 18px;
  text-align: left;
  color: #0674b9;
}
.Remnant-Length-Codes {
  font-family: Muli;
  font-size: 12px;
  text-align: left;
  color: #707070;
}
.Customise-Code {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: #101650;
}
.For-Raw-Material {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.44;
  text-align: left;
  color: #0674b9;
}
.code-desc {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
}
.Rectangle-3722 {
  max-width: 270px !important;
  padding: 6px;
  border-radius: 5px;
  border: solid 1px rgba(206, 210, 225, 0.97);
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
  color: #707070;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.enumFields {
  font-family: Muli;
  font-size: 18px;
  text-align: left;
  color: #101650;
}
.Rectangle-37222 {
  width: 150px !important;
  padding: 6px;
  border-radius: 5px;
  border: solid 1px rgba(206, 210, 225, 0.97);
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
  color: #707070;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #fff;
}

.AssistanceIcon {
  background-image: url("../Images/Inventory/AssistanceIconBlueBorder.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 70px;
  height: 60px;
}

.AssistanceIcon:hover {
  cursor: pointer;
  background-image: url("../Images/Inventory/AssistanceHover.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 120px;
  height: 60px;
}

.whiteTextModal {
  font-family: Muli;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.RequestButton {
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  text-align: center;
  color: #0674b9;
  font-family: Muli;
  font-size: 17px;
  border: 0;
  font-weight: bold;
}

.RequestButton:hover:enabled {
  cursor: pointer;
  background-color: #2da4d0;
  color: #fff;
}

.RequestButton:disabled {
  opacity: 0.1;
  cursor: unset;
}

.noThanks {
  font-family: Muli;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  border-bottom: solid 1px #fff;
}

.noThanks:hover {
  cursor: pointer;
  color: #2da4d0;
  border-bottom: solid 1px #2da4d0;
}
.Rectangle-9856 {
  max-height: 28px;
  padding: 5px;
  border-radius: 5px;
  border: solid 1px rgba(206, 210, 225, 0.97);
  font-family: Muli;
  font-size: 12px;
  text-align: left;
  color: #0674b9;
}
