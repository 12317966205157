.PricingTypeActive {
  padding: 1px 20px 4px 22px;
  border-radius: 19px;
  box-shadow: 0 3px 6px 0 #101650;
  border: solid 1px #101650;
  background-color: #fff;
  font-family: Muli;
  font-size: 20px;
  text-align: center;
  color: #0674b9;
}
.PricingTypeNotActive {
  font-family: Muli;
  font-size: 20px;
  text-align: center;
  color: #101650;
}
.PricingTypeNotActive:hover {
  cursor: pointer;
  padding: 1px 20px 4px 22px;
  border-radius: 19px;
  box-shadow: 0 3px 6px 0 #101650;
  border: solid 1px #101650;
  background-color: #fff;
  font-family: Muli;
  font-size: 20px;
  text-align: center;
  color: #0674b9;
}
.Sub-ContractorC {
  width: 116px;
  height: 67px;

  font-family: Muli;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.25;
  text-align: left;
  color: #101650;
}
.Free-Plan {
  font-family: Muli;
  font-size: 18px;
  line-height: 1;
  text-align: left;
  color: rgba(45, 164, 208, 0.7);
}
.ManuPlanName {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: #101650;
}
.Change-Plan {
  font-family: Muli;
  font-size: 22px;
  font-weight: normal;

  line-height: 1.5;

  text-align: left;
  color: #101650;
}
.helpText {
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.planDate {
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #2da4d0;
}
.plan-name {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.plan-price {
  font-family: Muli;
  font-size: 33px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.plan-period {
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.plan-Type {
  font-family: Muli;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: rgba(16, 22, 80, 0.7);
}
.plan-description {
  width: 200px;
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.plan-subscribed {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.Modal-title {
  font-size: 20px;
  font-family: Muli;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  color: #101650;
}

.Rectangle-972 {
  height: 63px;

  border: solid 1px #fff;
  background-color: #e9e7e7;
}
.SaveModal {
  padding: 6px;

  border: 0;
  border-radius: 5px;
  background-color: #0b5cab;
  font-family: Muli;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.exitModal {
  height: 32px;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  font-family: Muli;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: rgba(16, 22, 80, 0.7);
  background-color: #fff;
}
.message {
  font-family: Muli;

  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #101650;
}

.plan-description2 {
  width: 300px;
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
