.bg-class {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-image: url("../Images/ProfileDetails/SaaSbackground.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  padding-bottom: 10%;
  min-height: 100vh;
}
.Rectangle-1224 {
  padding: 10px;
  border-radius: 5px;
  border: 0;
  background-color: transparent;
  font-family: Muli;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #444;
}
.AddButton {
  height: 30px;
  width: 57px;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(16, 22, 80, 0.5);
  background-color: #fff;
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: center;
  color: #0674b9;
  margin-left: 1%;
}
.AddButton:hover {
  background-color: #0674b9;
  cursor: pointer;
  color: #fff;
}
.AddButton:disabled:hover {
  cursor: unset;
  background-color: #b4b4b4;
  color: #fff;
}
.AddButton:disabled {
  background-color: #b4b4b4;
  color: #fff;
}
.back-button {
  width: 57px;
  border-radius: 5px;
  height: 30px;
  border: 0;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #444;
}
.back-button:hover {
  background-color: #bcbcbc;
  cursor: pointer;
  color: #fff;
}

.view-dashboard {
  width: 200px;
  border-radius: 5px;
  height: 30px;
  border: 0;
  background-color: #fff;
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: #444;
}
.view-dashboard:hover {
  background-color: #bcbcbc;
  cursor: pointer;
  color: #fff;
}

.Search-for-Project {
  font-family: Muli;
  font-size: 23px;
  font-weight: bold;

  text-align: center;
  color: #101650;
}
.search-hint {
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: center;
  color: #101650;
}
.Page-Title {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;

  text-align: center;
  color: #101650;
}
.projectCard {
  height: 107px;

  box-shadow: 0 12px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}
.ACTION {
  font-family: Muli;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.Project-Name {
  font-family: Muli;
  font-size: 23px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.Client-Name {
  font-family: Muli;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.ActionButtons {
  width: 85px;
  height: 29px;
  border-radius: 5px;
  border: solid 1px #5fba47;
  font-family: Muli;
  background-color: #fff;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: center;
  color: #5fba47;
}
.ActionButtons:hover {
  background-color: #5fba47;
  color: #fff;
}

.table-header {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  width: 150px;
  line-height: 1.44;

  text-align: left;
  color: #101650;
}

.Line-523 {
  width: 100%;
  height: 0px;
  margin-top: 10px;
  border: solid 0.5px #fff;
}
.assigned-table-header {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;

  line-height: 1.44;

  text-align: left;
  color: #101650;
}
.EmptyMessage {
  height: 100%;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: rgba(6, 116, 185, 0.5);
}
.Sub-title {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: rgba(6, 116, 185, 0.5);
}
.MoveToProduction {
  border: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(16, 22, 80, 0.5);
  background-color: #fff;
  font-family: Muli;
  font-size: 11px;
  text-align: left;
  color: #0674b9;
  margin-left: 1%;
}
.MoveToProduction:hover {
  background-color: #0674b9;
  cursor: pointer;
  color: #fff;
}
/* .MoveToProduction:disabled:hover {
  cursor: unset;
  background-color: #b4b4b4;
  color: #fff;
} */
.MoveToProduction:disabled {
  cursor: unset;
  background-color: #b4b4b4;
  color: #fff;
}