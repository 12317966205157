.Manage-trucks-and-trailers {
  font-family: Muli;
  font-size: 17px;
  line-height: 1.24;
  text-align: center;
  color: #101650;
}
.Truck-List {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.28;
  text-align: left;
  color: #101650;
}
.back-button {
  width: 57px;
  border-radius: 5px;
  height: 30px;
  border: 0;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #444;
}
.back-button:hover {
  background-color: #bcbcbc;
  cursor: pointer;
  color: #fff;
}

.add-button {
  width: 57px;
  height: 30px;
  border: 0;
  background-color: #fff;
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #0674b9;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(16, 22, 80, 0.5);
}
.add-button:hover {
  background-color: #0674b9;
  cursor: pointer;
  color: #fff;
}
.EmptyMessage {
  height: 100%;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: rgba(6, 116, 185, 0.5);
}
.Truck-Detail {
  font-family: Muli;
  font-size: 20px;
  line-height: 1.25;
  text-align: center;
  color: #000;
}
.Path-4142 {
  height: 0;
  border: solid 0.3px #707070;
  opacity: 0.5;
}
.FieldLa {
  font-family: Muli;
  font-size: 12px;
  line-height: 1.25;
  text-align: left;
  color: #303030;
}
.SaveBut {
    padding: 7px 22px 6px 22px;
    border-radius: 5px;
    background-color: #0674b9;
    font-family: Muli;
    font-size: 13px;
    text-align: center;
    color: #fff;
    border: 0;
  }
  
  .SaveBut:hover {
    cursor: pointer;
    background-color: #101650;
  }
  
  .SaveBut:disabled {
    background-color: #bcbcbc;
  }
  
  .SaveBut:disabled:hover {
    background-color: #707070;
    cursor: unset;
  
  }