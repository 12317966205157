.page-title {
  width: 85px;
  height: 30px;
  margin: 0 2.5px 19px 0;
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.OkButton {
    border-radius: 5px;
    background-color: #0b5cab;
    font-family: Muli;
    font-size: 19px;
    text-transform: none;
    color: white;
    padding: 5px 10px 5px 10px
  
}

.OkButton:hover{
  cursor: pointer;
}
.Line12 {
  height: 1px;
  opacity: 0.8;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: #fff;
}
.View-all-Activities {
  font-family: SegoeUI;
  font-size: 14px;
  line-height: 1.36;
  text-align: left;
  color: #101650;
}
.Activity-Details {
  width: fit-content;
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #000;
  border-bottom: solid 1px #e1e319;
  padding-bottom: 7px;
}
.show-document {
  width: fit-content;

  border-radius: 5px;
  border: solid 1px #f3f3f3;
  background-color: #f3f3f3;
  padding: 10px;
  font-family: Muli;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  color: #707070;
}
.field-title {
  font-family: Muli;
  font-size: 16px;
  text-align: left;
  color: #707070;
}
.Rectangle-1464 {
  padding: 13px;
  border-radius: 5px;
  border: solid 1px #f3f3f3;
  font-family: Muli;
  font-size: 17px;
  text-align: left;
  color: #707070;
}
.errorMessage {
  font-size: 10px;
  font-family: Muli;
  color: #ff0000;
  height: 10px;
}
.labelInput {
  font-family: Muli;
  font-size: 17px;
  text-align: left;
  color: #bcbcbc;
  padding-top: 5px;
}
.fieldDesign {
  height: 50px;
  border-radius: 5px;
  border: 0;
  font-family: Muli;
  font-size: 17px;
  text-align: center;
  color: #707070;
  background-color: #fff;
}
.DescriptionfieldDesign {
  height: 157px;
  border-radius: 5px;
  border: solid 1px #f3f3f3;
  font-family: Muli;
  font-size: 17px;
  text-align: start;
  color: #707070;
  display: inline-block;
  justify-content: flex-start;
}
.field-title-disabled {
  font-family: Muli;
  font-size: 16px;
  text-align: left;
  color: #0674b9;
}
.SaveButton {
  padding: 5px;
  width: 60px;
  border-radius: 5px;
  background-color: #0b5cab;
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: #fff;
  border: 0;
}
.SaveButton:hover {
  cursor: pointer;
  background-color: #fff;
  color: #0674b9;
  border: solid 1px #0674b9;
}
.SaveButton:disabled {
  background-color: #707070;
  color: #fff;
  border: solid 1px #707070;
}
.SaveButton:disabled:hover {
  cursor: unset;
  background-color: #707070;
  color: #fff;
  border: solid 1px #707070;
}
.BackButton {
  padding: 5px;
  width: 60px;
  border-radius: 5px;
  background-color: #fff;
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: #707070;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  border: 0;
}
.BackButton:hover {
  cursor: pointer;
  background-color: #0674b9;
  color: #fff;
}
.modalTitles {
  font-family: Muli;
  font-weight: bold;
  font-size: 18px;
  color: #101650;
}
.Rectangle-1519 {
  padding: 10px;
  background-color: #fff;
  font-family: Muli;
  font-size: 15px;
  color: #000;
}
.Addcomment {
  font-family: Muli;
  font-size: 17px;
  line-height: 1.47;
  text-align: left;
  color: #bcbcbc;
}
.commentorName {
  font-family: Muli;
  font-size: 17px;
  line-height: 1.47;
  text-align: left;
  color: #707070;
}
.commentDate {
  font-family: Muli;
  font-size: 13px;
  font-weight: 300;
  text-align: left;
  color: #707070;
}
.CommentDesc {
  font-family: Muli;
  font-size: 17px;
  text-align: left;
  color: #707070;
}
.Edit-Delete {
  font-family: Muli;
  font-size: 17px;
  font-weight: bold;
  text-align: left;
  color: #707070;
}
.Delete-this-comment {
  font-family: Muli;
  font-weight: bold;
  text-align: left;
  color: #707070;
}
.deleteHint {
  font-family: Muli;
  font-size: 17px;
  font-weight: 300;
  line-height: 1.47;
  text-align: left;
  color: #707070;
}
.CancelModal {
  font-family: Muli;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  color: #707070;
}
.CancelModal:hover {
  cursor: pointer;
}
.DeleteButton {
  padding: 10px;
  border-radius: 5px;
  background-color: #ea001e;
  font-family: Muli;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border: 0;
}
.DeleteButton:hover {
  cursor: pointer;
}
.Path-1278 {
  width: fit-content;
  border: solid 1px rgba(45, 164, 208, 0.7);
  padding: 5px;
  border-radius: 5px;
}
.startActivity {
  padding: 13px;
  background-color: rgba(45, 164, 208, 0.7);
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  border: 0;
}
.startActivity:hover {
  cursor: pointer;
}
.startActivity:disabled:hover {
  cursor: unset;
}
.UploadFile {
  font-family: Muli;
  font-size: 17px;
  font-weight: bold;
  text-align: left;
  color: #fff;
  border-radius: 5px;
  background-color: rgba(16, 22, 80, 0.7);
  border: 0;
  padding: 10.3px 82px 14.7px 18px;
}
.UploadFile:hover {
  cursor: pointer;
  background-color: #0674b9;
}
.fileText {
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
  color: #444;
}
.FileName {
  font-family: Muli;
  font-size: 12px;
  text-align: left;
  color: #444;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.submitActivity {
  padding: 9px 36px 8px 36px;
  background-color: rgba(45, 164, 208, 0.7);
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  border: 0;
}
.submitActivity:hover {
  cursor: pointer;
}
.Rectangle-1466 {
  border-radius: 5px;
  border: solid 1px #f3f3f3;
  background-color: #fff;
  font-weight: bold;
  text-align: left;
  padding: 10px;
}
.Rectangle-1467 {
  border-radius: 5px;
  border: solid 1px #f3f3f3;
  background-color: #fff;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  padding: 10px;
  color: #0674b9;
}
.Rectangle-1467:hover {
  cursor: pointer;
  background-color: #0674b9;
  color: #fff;
}
.Activity-Versions {
  font-family: Muli;
  font-size: 11px;
  line-height: 1.18;
  text-align: left;
  color: #707070;
}
.Rectangle-1348 {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 7px;
  background-color: #f1f1f1;
  text-align: center;
  font-family: Muli;
  font-size: 11px;
  width: fit-content;
  color: #707070;
}
.Rectangle-1607 {
  border-radius: 11px;
  background-color: #f2f2f2;
}
.Ellipse-grey {
  width: 23px;
  height: 22px;
  background-color: #d9d2d2;
  border-radius: 30px;
}
.Ellipse-grey {
  width: 23px;
  height: 22px;

  border-radius: 30px;
}
.Ellipse-grey:hover {
  cursor: pointer;
}
.AcceptButton {
  width: 207px;
  padding: 5px;
  background-color: rgba(45, 164, 208, 0.7);
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border: 0;
  border-radius: 2px;
}
.AcceptButton:hover {
  cursor: pointer;
  background-color: #1477b8;
}
.AcceptButton:disabled {
  cursor: unset;
  background-color: #707070;
}
.Path-1279 {
  width: fit-content;
  border: solid 1px rgba(45, 164, 208, 0.7);
  padding: 5px;
  border-radius: 5px;
}
.submitActivityTo {
  padding: 9px 15px 8px 8px;
  background-color: rgba(45, 164, 208, 0.7);
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  border: 0;
}
.submitActivityTo:hover {
  cursor: pointer;
}
.submitActivityTo:disabled {
  background-color: #707070;
}
.submitActivityTo:disabled:hover {
  background-color: #707070;
  cursor: unset;
}
.back-button {
  padding: 5px;
  border-radius: 5px;
  border: 0;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  text-align: center;
  color: #444;
}
.back-button:hover {
  background-color: #bcbcbc;
  cursor: pointer;
  color: #fff;
}
.Submittals-dates {
  font-family: Muli;
  font-style: italic;
  line-height: 1.5;
  color: #101650;
}
.Upload-Submittal-Response {
  font-family: Muli;
  font-size: 17px;
  text-align: left;
  color: #707070;
  text-transform: none;
}
.UploadfieldDesign {
  height: 50px;
  border-radius: 5px;
  border: solid 1px #f3f3f3 !important;
  font-family: Muli;
  font-size: 17px;
  text-align: center;
  color: #707070;
  background-color: #fff;
}
.Rectangle-1465 {
  padding: 13px;
  border-radius: 5px;
  border: solid 1px #f3f3f3;
  font-family: Muli;
  font-size: 17px;
  text-align: left;
  color: #707070;
  height: 178px !important;
}
.Activity-Details2 {
  width: fit-content;
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #101650;
  border-bottom: solid 1px #e1e319;
  padding-bottom: 7px;
}
.Data-title {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  color: rgba(16, 22, 80, 0.7);
}
.Actual-data {
  font-family: Muli;
  font-size: 16px;
  text-align: left;
  color: #707070;
}
.Submitted-status {
  font-family: Muli;
  font-size: 16px;
  font-style: italic;
  text-align: right;
  color: #101650;
}
.Path-1280 {
  width: fit-content;
  border: solid 1px #1477b8;
  padding: 5px;
  border-radius: 5px;
}
.yesResponse {
  width: 72.5px;
  padding: 10px;
  background-color: #1477b8;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  border: 0;
}
.yesResponse:hover {
  cursor: pointer;
}
.Consultant-Response-Received {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #101650;
}
.Path-1281 {
  width: fit-content;
  border: solid 1px rgba(45, 164, 208, 0.7);
  padding: 5px;
  border-radius: 5px;
}
.NosubmitActivityTo {
  padding: 11px;
  background-color: rgba(45, 164, 208, 0.7);
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  border: 0;
}
.NosubmitActivityTo:hover {
  cursor: pointer;
}
.downloadALL {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #0674b9;
  font-family: Muli;
  font-size: 12px;
  text-align: center;
  color: #fff;
  border: 0;
}
.downloadALL:hover {
  cursor: pointer;
  background-color: #101650;
}
.Union-106 {
  border: 0;
  padding: 10px;
  background-color: rgba(45, 164, 208, 0.7);
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #fff;
}
.Union-106:disabled {
  background-color: #bcbcbc;
}
.Union-106:hover {
  cursor: pointer;
}
.Union-106:disabled:hover {
  cursor: unset;
  background-color: #bcbcbc;
}
.Rejected {
  font-family: Muli;
  font-size: 17px;
  font-style: italic;
  text-align: left;
  color: #707070;
}
