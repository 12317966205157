.back-button {
  width: 57px;
  border-radius: 5px;
  height: 30px;
  border: 0;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #444;
}

.back-button:hover {
  background-color: #bcbcbc;
  cursor: pointer;
  color: #fff;
}

.confirm-button {
  border: solid 1px #0674b9;
  background-color: #fff;
  color: #0674b9;
  width: 66px;
  font-size: 14;
  border-radius: 5px;

}

.confirm-button:hover {
  cursor: pointer;

}

.confirm-button:disabled {
  cursor: unset;

}

.Title {
  font-family: Muli;
  font-size: 18px;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.ViewText {
  font-family: Muli;
  font-size: 14px;
  line-height: 1.36;
  color: #101650;
}

@media only screen and (max-width: 600px) {
  .ViewText {
    font-size: 10px;
  }

  .Title {
    font-size: 10px;
  }

  .results-scanned-chosen {
    font-size: 10px;
  }

  .results-scanned-not-chosen {
    font-size: 10px;
  }
}

.ViewText:hover {
  cursor: pointer;
}

.BayCode {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  line-height: 0.75;
  text-align: center;
  color: #101650;
}

.BlockTitle {
  width: 32px;
  font-family: Muli;
  font-size: 8px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: #707070;
}

.Rectangle-3010 {
  width: 19px;
  height: 10px;
  border-radius: 7px;
  background-color: #f1f1f1;
  text-align: center;
  color: #707070;
  font-family: Muli;
  font-size: 8px;
}

.BlockTitle2 {
  font-family: Muli;
  font-size: 8px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: #707070;
}

.Resources {
  width: 30px;
  height: 14px;
  font-family: Muli;
  font-size: 5px;
  font-style: italic;
  line-height: 1.4;
  text-align: left;
  color: #0674b9;
}

.Routes {
  font-family: Muli;
  font-size: 9px;
  font-style: italic;
  line-height: 1.56;
  text-align: left;
  color: #2da4d0;
}

.Plus {
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: #2da4d0;
}

.TFTitle {
  font-family: Muli;
  font-size: 9px;
  line-height: 1.56;
  text-align: left;
  color: #bcbcbc;
}

.Rectangle-3005 {
  height: 28px;
  padding: 4px 9.5px 6px 12px;
  border-radius: 5px;
  border: solid 1px rgba(206, 210, 225, 0.97);
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
  color: #707070;
}

.Zones {
  font-family: Muli;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #101650;
}

.plus-hint {
  font-family: Muli;
  font-size: 11px;
  line-height: 1.45;
  text-align: left;
  color: #0674b9;
}

.titlesForResources {
  font-family: Muli;
  font-size: 11px;
  line-height: 1.45;
  text-align: left;
  color: #707070;
}

.cancelbut {
  padding: 7px 14px 6px 15px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  font-family: Muli;
  font-size: 13px;
  line-height: 1.46;
  text-align: center;
  color: #0674b9;
  border: 0;
}

.cancelbut:hover {
  cursor: pointer;
  background-color: #0674b9;
  cursor: pointer;
  color: #fff;
}

.cancelbut:disabled {
  background-color: #bcbcbc;
  color: #fff;
}

.SaveBut {
  padding: 7px 22px 6px 22px;
  border-radius: 5px;
  background-color: #0674b9;
  font-family: Muli;
  font-size: 13px;
  text-align: center;
  color: #fff;
  border: 0;
}

.SaveBut:hover {
  cursor: pointer;
  background-color: #101650;
}

.SaveBut:disabled {
  background-color: #bcbcbc;
}

.SaveBut:disabled:hover {
  background-color: #707070;
  cursor: unset;
}

.save-button {
  padding: 7px 22px 7px 22px;
  border-radius: 5px;
  background-color: #0674b9;
  font-family: Muli;
  font-size: 13px;
  text-align: center;
  color: #fff;
  border: 0;
}

.save-button:hover {
  cursor: pointer;
  background-color: #101650;
  color: #fff;
}

.DeleteButton {
  padding: 5px;
  border-radius: 5px;
  background-color: #ea001e;
  font-family: Muli;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border: 0;
}

.DeleteButton:hover {
  cursor: pointer;
}

.OptimizationParameters {
  font-family: Muli;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.ParameterType {
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: #0674b9;
}

.ParametersFields {
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.MinMaxFields {
  font-family: Muli;
  font-size: 9px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: left;
  color: #bcbcbc;
}

.checkbox {
  width: 16px;
  height: 16px;
  margin: 6px 19px 7px 15px;
  border-radius: 2px;
  border: solid 1px #707070;
  background-color: #fff;
}

.cancel-button {
  width: 57px;
  border-radius: 5px;
  height: 30px;
  border: 0;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #0674b9;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.cancel-button:hover {
  background-color: #bcbcbc;
  cursor: pointer;
  color: #fff;
}

.fieldUploadDesign {
  height: 40px;
  border-radius: 5px;
  border: solid 1px #f3f3f3;
  background-color: #fff;
  font-family: Muli;
  font-size: 12px;
  text-align: left;
  color: #707070;
}

.fileText {
  font-family: Muli;
  font-size: 12px;
  text-align: left;
  font-family: Muli;
  color: #707070;
  text-transform: none;
}

.Label-Printer {
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
  color: #707070;
}

.CheckedBox {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background-color: #e1e319;
}

.notCheckedBox {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: solid 1px #707070;
  background-color: #fff;
}

.notCheckedBox:hover {
  cursor: pointer;
  background-color: #e1e319;
}

.Shape-types-accepted {
  font-family: Muli;
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
  color: #707070;
}

.CheckedBoxMachine {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background-color: #e1e319;
}

.CheckedBoxMachine:hover {
  cursor: pointer;
}

.Rectangle-3008 {
  border-radius: 5px;
  background-color: #fff;
}

.Factory-Name {
  font-family: Muli;
  font-size: 10px;
  font-weight: bold;
  text-align: left;
  color: #101650;
}

.CodeStyle {
  font-size: 8px;
  font-weight: normal;
  color: #707070;
  text-align: left;
  font-family: Muli;
}

.hint-system {
  font-family: Muli;
  font-size: 9px;
  line-height: 1.56;
  text-align: left;
  color: #0674b9;
}

.Path-4116 {
  width: 108.5px;
  height: 41.3px;
  border: solid 1px #bcbcbc;
  background-color: #fcfafa;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.menu-st {
  font-family: Muli;
  font-size: 8px;
  font-weight: bold;
  text-align: left;
  color: #707070;
}

.greyText {
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #707070;
}

.goToProduction {
  padding: 7px;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f1f1f1;
  text-align: left;

  border: 0;
}

.goToProduction:hover {
  cursor: pointer;
  background-color: #ecc0c0;

}

.AssistanceIcon {
  background-image: url("../Images/Inventory/AssistanceIconBlueBorder.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 70px;
  height: 60px;
}

.AssistanceIcon:hover {
  cursor: pointer;
  background-image: url("../Images/Inventory/AssistanceHover.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.ViewMore {
  padding: 7px;
  border-radius: 5px;
  background-color: #0674b9;
  font-family: Muli;
  font-size: 8px;
  text-align: center;
  color: #fff;
  border: 0;
}

.ViewMore:hover {
  cursor: pointer;
  background-color: #101650;
}

.ViewMore:disabled {
  background-color: #bcbcbc;
}

.ViewMore:disabled:hover {
  background-color: #707070;
  cursor: unset;
}

.TransferButton {
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(16, 22, 80, 0.5);
  background-color: #101650;
  font-family: Muli;
  font-size: 10px;
  line-height: 1.3;
  text-align: center;
  color: #fff;
}

.TransferButton:hover {
  cursor: pointer;
  background-color: #090e38;
}

.TransferButton:disabled {
  background-color: #707070;
}

.TransferButton:disabled:hover {
  background-color: #707070;
  cursor: unset;
}

.selectCircle {
  width: 21px;
  height: 21px;
  border-radius: 50px;
  border: solid 1px #bcbcbc;
  background-color: #fff;
}

.selectCircle:hover {
  cursor: pointer;
  background-color: rgba(225, 227, 25, 0.7);
}

.selectCircleSelected {
  width: 21px;
  height: 21px;
  border-radius: 50px;
  border: solid 1px #bcbcbc;
  background-color: rgba(225, 227, 25, 0.7);
}

.selectCircleSelected:hover {
  cursor: pointer;
}

.InputBorder {
  font-family: Muli;
  font-size: 12px;
  line-height: 1.25;
  text-align: left;
  color: #707070;
  padding: 5px;
  border-radius: 5px;
  border: solid 1px rgba(206, 210, 225, 0.97);
}

.inputTitle {
  font-family: Muli;
  font-size: 9px;
  letter-spacing: normal;
  text-align: left;
  color: #bcbcbc;
}

.TransferOneButton {
  padding: 7px 21px 6px 23px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(16, 22, 80, 0.5);
  background-color: #101650;
  font-family: Muli;
  font-size: 10px;
  line-height: 1.3;
  text-align: center;
  color: #fff;
  border: 0;
}

.TransferOneButton:hover {
  cursor: pointer;
  background-color: #090e38;
}

.Transfer-title {
  font-family: Muli;
  font-size: 21px;
  text-align: center;
  color: #fff;
}

.drop-title {
  font-family: Muli;
  font-size: 10px;
  text-align: left;
  color: #fff;
}

.TransfeMB {
  padding: 9px;
  border-radius: 5px;
  border: solid 1px #e9e7e7;
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  line-height: 1.3;
  text-align: center;
  color: #444;
  border: 0;
}

.TransfeMB:hover {
  cursor: pointer;
  background-color: #c6e1d0;
}

.TransfeMB:disabled {
  background-color: #707070;
}

.TransfeMB:disabled:hover {
  background-color: #707070;
  cursor: unset;
}

.deleteMB {
  padding: 9px 15px 9px 15px;
  border-radius: 5px;
  border: solid 1px #e9e7e7;
  background-color: #fff;
  font-family: Muli;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
  color: #444;
  border: 0;
}

.deleteMB:hover {
  cursor: pointer;
  background-color: #c6e1d0;
}

.SubTitle {
  font-family: Muli;
  font-size: 11px;
  font-weight: bold;
  line-height: 0.91;
  text-align: center;
  color: #0674b9;
}

.subsubTitiles {
  font-family: Muli;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.45;
  text-align: left;
  color: #707070;
}

.StockCount {
  padding: 4px 11px 5px 9px;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(16, 22, 80, 0.5);
  border: solid 1px #101650;
  font-family: Muli;
  font-size: 8px;
  line-height: 1.25;
  text-align: center;
  color: #101650;
  background-color: transparent;
}

.StockCount:hover {
  cursor: pointer;
  background-color: #101650;
  color: #fff;
}

.Titlestock {
  font-family: Muli;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.AddCount {
  padding: 9px;
  border-radius: 5px;
  background-color: #f3df3d;
  font-family: Muli;
  font-size: 10px;
  line-height: 1.3;
  text-align: center;
  font-weight: bold;
  color: #101650;
  border: 0;
}

.AddCount:hover {
  cursor: pointer;
  background-color: #e1e319;
}

.AddCount:disabled {
  background-color: #707070;
}

.AddCount:disabled:hover {
  background-color: #707070;
  cursor: unset;
}

.FinishScan {
  padding: 8px 28px 8px 25px;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(16, 22, 80, 0.5);
  border: solid 1px #101650;
  font-family: Muli;
  font-size: 12px;
  line-height: 1.25;
  text-align: center;
  color: #101650;
  background-color: transparent;
}

.FinishScan:hover {
  cursor: pointer;
  background-color: #101650;
  color: #fff;
}

.scrollTable::-webkit-scrollbar {
  width: 12px;
}

.scrollTable::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  border-radius: 10px;
}

.scrollTable::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #707070;
}

.countResults {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.25;
  text-align: left;
  color: #535151;
}

.results-scanned-not-chosen {
  font-family: Muli;
  font-size: 15px;
  line-height: 1.25;
  text-align: center;
  color: #101650;
  padding: 10px 9px 10px 9px;
  border-radius: 5px;
  border: solid 1px #101650;
  background-color: transparent;
}

.results-scanned-not-chosen {
  cursor: pointer;
}

.results-scanned-chosen {
  font-family: Muli;
  font-size: 15px;
  line-height: 1.25;
  text-align: center;
  color: #fff;
  padding: 10px 9px 10px 9px;
  border-radius: 5px;
  border: solid 1px #101650;
  background-color: #101650;
}

.results-scanned-chosen {
  cursor: pointer;
}

.Docks {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.25;
  text-align: left;
  color: #707070;
}

.ttinput {
  font-family: Muli;
  font-size: 12px;
  text-align: left;
  color: #000;
}

.cancelbuttool {
  padding: 2.3px 8.8px 3.2px 7.3px;
  border-radius: 2px;

  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;
  line-height: 1.46;
  text-align: center;
  color: #0674b9;
  border: 0;
}

.cancelbuttool:hover {
  cursor: pointer;
  background-color: #0674b9;
  cursor: pointer;
  color: #fff;
}

.cancelbuttool:disabled {
  background-color: #bcbcbc;
  color: #fff;
}

.SaveButtool {
  padding: 2.3px 8.8px 3.2px 7.3px;
  border-radius: 2px;
  background-color: #0674b9;
  font-family: Muli;
  font-size: 10px;
  text-align: center;
  color: #fff;
  border: 0;
}

.SaveButtool:hover {
  cursor: pointer;
  background-color: #101650;
}

.SaveButtool:disabled {
  background-color: #bcbcbc;
}

.SaveButtool:disabled:hover {
  background-color: #707070;
  cursor: unset;
}

.bayNotSelected {
  height: 20px;
  padding: 2.3px 8.8px 3.2px 7.3px;
  border-radius: 2px;
  background-color: #fff;
  font-family: Muli;
  font-size: 8px;
  line-height: 1.46;
  text-align: center;
  color: #0674b9;
  border: 0;
}

.bayNotSelected:hover {
  cursor: pointer;
  background-color: #0674b9;
  color: #fff;
}

.baySelected {
  height: 20px;

  padding: 2.3px 8.8px 3.2px 7.3px;
  border-radius: 2px;
  background-color: #0674b9;
  font-family: Muli;
  font-size: 8px;
  line-height: 1.46;
  text-align: center;
  color: #fff;
  border: 0;
}

.baySelected {
  cursor: pointer;
}