.SaasDrawer {
  font-family: Muli;
  font-size: 15px;
  line-height: 1.47;
  text-align: left;
  color: #0674b9;
}
.DrawerItem {
  font-family: Muli;
  font-size: 15px;
  line-height: 1.47;
  text-align: left;
  color: #0674b9;
  text-decoration: none;
  font-weight: normal;
}
.DrawerItemActive {
  color: #0674b9;
  width: fit-content;
  border-bottom: 2px solid #101650;
}
.CompanyName {

  max-width: 100px;
  font-family: Muli;
  font-size: 15px;
  text-align: center;
  color: #0674b9;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Divi {
  width: 330.5px;
  height: 0;
  border: solid 1px #f2f2f2;
}
.NavTitle {
  font-family: Muli;
  font-size: 15px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #101650;
}
.CompanyNameComplete {

  font-family: Muli;
  font-size: 15px;
  text-align: center;
  color: #0674b9;
  display: block;
  overflow-wrap: break-word;
}