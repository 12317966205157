.headline {
  font-family: Muli;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.04;
  letter-spacing: normal;
  text-align: center;
  color: #101650;
}
.subHeadline {
  font-family: Muli;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  width: 37%;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  margin: 0 auto;
}

.MC-Circle {
  width: 29px;
  height: 29px;
  margin: 0 1px 0 0;
  border-radius: 50px;
  background-color: rgba(206, 210, 225, 0.97);
  font-family: Muli;
  font-size: 15px;
  font-weight: bold;

  line-height: 2.13;

  text-align: center;
  color: #fff;
}
.MC-CircleActive {
  width: 29px;
  height: 29px;
  margin: 0 1px 0 0;
  border-radius: 50px;
  background-color: rgba(225, 227, 25, 0.7);
  font-family: Muli;
  font-size: 15px;
  font-weight: bold;

  line-height: 2.13;

  text-align: center;
  color: #fff;
}
.Main-Contractor {
  width: 116px;
  height: 67px;

  font-family: Muli;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.Sub-Contractor {
  width: 116px;
  height: 67px;

  font-family: Muli;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
@media only screen and (max-width: 900px) {
  .Main-Contractor {
    text-align: center;
    color: rgba(16, 22, 80, 0.7);
  }
  .Sub-Contractor {
    text-align: center;
    color: rgba(16, 22, 80, 0.7);
  }
}
.SC-Circle {
  width: 29px;
  height: 29px;
  margin: 0 1px 0 0;
  border-radius: 50px;
  background-color: rgba(206, 210, 225, 0.97);
  font-family: Muli;
  font-size: 15px;
  font-weight: bold;

  line-height: 2.13;

  text-align: center;
  color: #fff;
}
.SC-CircleActive {
  width: 29px;
  height: 29px;
  margin: 0 1px 0 0;
  border-radius: 50px;
  background-color: rgba(45, 164, 208, 0.7);
  font-family: Muli;
  font-size: 15px;
  font-weight: bold;

  line-height: 2.13;

  text-align: center;
  color: #fff;
}
.planForm {
  width: 295px;
  height: 495px;
}
.planForm2 {
  width: 295px;
  height: 495px;
}
.Core {
  width: 50px;
  height: 26px;

  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.price {
  width: 63px;
  height: 53px;

  font-family: Muli;
  font-size: 36px;
  font-weight: 300;

  line-height: 1.47;

  text-align: left;
  color: #101650;
}
.duration {
  width: 50px;
  height: 20px;

  font-family: Muli;
  font-size: 14px;

  line-height: 1.43;

  text-align: left;
  color: #101650;
}
.name {
  width: 60px;
  height: 24px;
  font-family: Muli;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  text-align: left;
  color: rgba(16, 22, 80, 0.7);
}
.Button {
  width: 270px;
  height: 40px;
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.44;
  text-align: center;
  border: 0px;
  color: #fff;
  background-color: #0b5cab;
  cursor: pointer;
}
.Button:disabled {
  width: 270px;
  height: 40px;
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.44;
  text-align: center;
  border: 0px;
  color: rgba(6, 116, 185, 0.5);
  background-color: #f2f2f2;
  cursor: unset;
}
.description {
  width: 250px;
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.descriptionHeader {
  width: 154px;
  height: 24px;
  margin: 0 8px 0 0px;
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.23;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.descriptionHeader .text-style-1 {
  font-weight: bold;
}
.titlePhone {
  height: 130px;

  font-family: Muli;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #101650;
}
.titlePhone .text-style-1 {
  color: #e1e319;
}
@media only screen and (max-width: 450px) {
  .titlePhone {
    font-size: 5vw;
  }
}
.Payment-Failed {
  width: 150px;
  height: 28px;
  font-family: Muli;
  font-size: 19px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.Oh-no-your-payment-failed {
  width: 251px;
  height: 28px;

  font-family: Muli;
  font-size: 19px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;

  text-align: left;
  color: #000;
}
.Failed-Subscription {
  width: 132px;
  height: 57px;

  font-family: Muli;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.09;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.Failed-Subscription-description {
  width: 352px;
  height: 84px;

  font-family: Muli;
  font-size: 19px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.BackButton {
  width: 220px;
  height: 48px;
  border-radius: 7px;
  background-color: #0674b9;
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: 0;
}
.Payment-Complete {
  width: 174px;
  height: 28px;

  font-family: Muli;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.Congratulations {
  width: 151px;
  height: 28px;

  font-family: Muli;
  font-size: 19px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.Successful-Subscription {
  width: 132px;
  height: 57px;

  font-family: Muli;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.09;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.Successful-Subscription-description {
  width: 286px;
  height: 78px;

  font-family: Muli;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.getStarted1 {
  width: 220px;
  height: 48px;

  border-radius: 7px;
  background-color: #5fba47;
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: 0;
}
.Lets-Build-Your-Subscription {
  width: 300px;
  height: 28px;

  font-family: Muli;
  font-size: 19px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.Finalising-your-subscription {
  width: 251px;
  height: 28px;

  font-family: Muli;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.Custom-Pricing {
  height: 44px;
  padding-top: 12px;
  font-family: Muli;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #101650;
}
.Manu-hint {
  font-family: Muli;
  font-size: 15px;
  font-style: italic;
  line-height: 1.47;
  text-align: left;
  color: #0674b9;
}
