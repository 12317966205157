.Item-Label {
    font-family: Muli;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #101650;
}

.Item-Tag-No {
    font-family: Muli;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #0674b9;
}

.RM---1 {
    font-family: Muli;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #101650;
}

.bold-RM---1 {
    font-family: Muli;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #101650;
}

.Rectangle-4017 {
    padding: 25px 0px 30px 28px;
    opacity: 0.79;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #eaf0f5;
    background-color: #fff;
}

.cancel-button {
    padding: 12px 25px 12px 22px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    font-family: Muli;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: left;
    color: #0674b9;
    border: 0;
}

.cancel-button:hover {
    cursor: pointer;
}

.save-button {
    padding: 12px 30px 12px 29px;
    border-radius: 5px;
    background-color: #0674b9;
    font-family: Muli;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    border: 0;
}

.save-button:hover {
    cursor: pointer;
}

.confirm-button {
    padding: 12px 25px 12px 22px;
    border-radius: 5px;
    background-color: #0674b9;
    border: 0;
    font-family: Muli;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.confirm-button:hover {
    cursor: pointer;
}

.confirm-button:disabled {
    cursor: unset;
    background-color: #707070;
    color: #fff;
}

/* .confirm-button {
    padding: 10px 25px 10px 25px;
    border-radius: 5px;
    background-color: #0674b9;
    font-family: Muli;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    border: 0;
}

.confirm-button:hover {
    cursor: pointer;
}


.confirm-button:disabled {
    cursor: default;
    background-color: #82898d;
} */

.Rectangle-4024 {
    padding: 10px 23px 10px 22px;
    background-color: #42add4;
    font-family: Muli;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.Rectangle-4066 {
    padding: 24px 36px 29px 25px;
    border: solid 1px #707070;
    background-color: #fff;
    font-family: Muli;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    text-align: center;
    letter-spacing: normal;
    color: #101650;
}

.Material-Scanned {
    font-family: Muli;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #101650;
}

@media (max-width: 1530px) {
    .Rectangle-4017 {
        padding: 15px 0px 15px 15px;
    }
}

@media (max-width: 1200px) {
    .Rectangle-4017 {
        padding: 25px 0px 30px 15px;
    }

}