body,
html {
  height: 100%;
  margin: 0;
}
.Rectangle-955 {
  width: 887px;
  height: 132px;
  position: relative;
  border-radius: 10px;
  border: solid 1px #e9e7e7;
  background-color: #fff;
}
.Ellipse-447 {
  width: 140px;
  height: 140px;
  position: absolute;
  bottom: 40%;
  left: 3%;
  margin: -50px 0 0 -50px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #e9e7e7;
  box-shadow: 0 0 0 1px #e9e7e7, 0 0 0 3px white, 0 0 0 4px #e9e7e7;
}
.Menu-Buttons {
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #0674b9;
}
.Modal-title {
  width: 132px;
  height: 30px;
  font-family: Muli;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.divider2 {
  height: 0;
  background-color: #e9e7e7;
  border: solid 2px #e9e7e7;
}
.ModalDescription {
  font-family: Muli;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.Rectangle-971 {
  width: 124px;
  height: 35px;
  border-radius: 6px;

  border: solid 1px #707070;
  font-family: Muli;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;

  letter-spacing: normal;
  text-align: center;
  color: #101650;
}
.Rectangle-966 {
  width: 200px;
  height: 250px;

  border: solid 1px #e9e7e7;
  background-color: #fff;
}
.Rectangle-972 {
  height: 59px;

  border: solid 1px #fff;
  background-color: #e9e7e7;
}
.exitModal {
  width: 71px;
  height: 32px;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  font-family: Muli;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: rgba(16, 22, 80, 0.7);
  background-color: #fff;
}
.SaveModal {
  width: 71px;
  height: 32px;

  border: 0;
  border-radius: 5px;
  background-color: #0b5cab;
  font-family: Muli;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.DeletePhoto {
  width: 120px;
  height: 32px;

  border: 0;
  border-radius: 5px;
  background-color: #0b5cab;
  font-family: Muli;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.profileTitle {
  height: 35px;

  font-family: Muli;

  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.SloganEdit {
  width: 209px;
  height: 24px;

  opacity: 0.57;
  font-family: Muli;

  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.companySlogan {
  width: 90%;
  height: 50px;
  font-family: Muli;
  font-style: italic;
  line-height: 1.5;
  text-align: left;
  color: rgba(16, 22, 80, 0.7);
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
.companyWebsiteField {
  width: 93%;

  border-radius: 5px;

  background-color: #fff;
}
.Fiedlables {
  width: 173px;
  height: 30px;

  font-family: Muli;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.companySloganField {
  width: 93%;

  border-radius: 5px;

  background-color: #fff;
}
.websiteAfter {
  font-family: Muli;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #0674b9;
}
.Rectangle-962 {
  border-radius: 8px;
  border: solid 1px #e9e7e7;
  background-color: #fff;
}
.infoSectionTitle {
  font-family: Muli;
  font-size: 14px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.Line-356 {
  width: 60px;

  background-color: #2da4d0;
  border: solid 1px #2da4d0;
}
.Line1-356 {
  width: 200px;

  background-color: #2da4d0;
  border: solid 1px #2da4d0;
}
.Line-355 {
  width: 82.5%;
  background-color: #e9e7e7;
  border: solid 1px #e9e7e7;
}
.DetailsHeader {
  font-family: Muli;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.DetailsHeaderdisable {
  font-family: Muli;
  font-size: 10px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #bcbcbc;
}

.infoData {
  margin-top: 5px;
  font-family: Muli;
  font-size: 14px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}
.infoDataDisabled {
  font-family: Muli;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #0674b9;
}
.Line-347 {
  width: 78.2%;
  height: 0;
  background-color: #e9e7e7;
  border: solid 1px #e9e7e7;
}
