.GoBack {
  padding: 8px;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(16, 22, 80, 0.5);
  background-color: #fff;
  font-family: Muli;
  font-size: 10px;

  text-align: left;
  color: #444;
}
.GoBack:hover {
  cursor: pointer;
  background-color: rgb(133, 122, 122);

  color: #fff;
}
.Request-for-Information {
  font-family: Muli;
  font-size: 19px;
  text-align: center;
  color: #101650;
}
.Line-564 {
  box-shadow: 0 3px 6px 0 #e1e319;
  border: solid 1px #f2f2f2;
}
.fieldDesign {
  height: 50px;
  border-radius: 5px;
  border: 0;
  font-family: Muli;
  font-size: 17px;
  text-align: center;
  color: #707070;
  background-color: #fff;
}
.field-title {
  font-family: Muli;
  font-size: 16px;
  text-align: left;
  color: #707070;
}
.fileText {
  font-family: Muli;
  font-size: 17px;
  text-align: left;
  font-family: Muli;
  color: #707070;
  text-transform: none;
}
.fieldUploadDesign {
  height: 50px;
  border-radius: 5px;
  border: solid 1px #f3f3f3;
  background-color: #f3f3f3;
  font-family: Muli;
  font-size: 17px;
  text-align: left;
  color: #707070;
}
.notChecked {
  width: 23px;
  height: 23px;
  border: solid 1px rgba(206, 210, 225, 0.97);
  background-color: #fff;
}
.notChecked:hover {
  cursor: pointer;
  background-color: #e1e319;
}
.checked {
  width: 23px;
  height: 23px;
  background-color: #e1e319;
}
.checked:hover {
  cursor: pointer;
}
.AssigneOption {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: #707070;
}
.field-title-disabled {
  font-family: Muli;
  font-size: 16px;
  text-align: left;
  color: #0674b9;
}
.Rectangle-1464 {
  padding: 13px;
  border-radius: 5px;
  border: solid 1px #f3f3f3;
  font-family: Muli;
  font-size: 17px;
  text-align: left;
  color: #707070;
}
.errorMessage {
  font-size: 10px;
  font-family: Muli;
  color: #ff0000;
  height: 10px;
}
.DataTitle {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #101650;
}
.Actualdata {
  font-family: Muli;
  font-size: 17px;
  text-align: left;
  color: #707070;
}
.DownloadAll {
  width:fit-content;
  padding: 5px;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #0674b9;
  font-family: Muli;
  font-size: 12px;
  text-align: center;
  color: #fff;
}
.DownloadAll:hover {
  cursor: pointer;
  background-color: #101650;
}
.TableHeader {
  font-family: Muli;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  color: #101650;
}
.Rectangle-43 {
  box-shadow: 0 12px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}
.ViewRFI {
  padding: 8px;
  border-radius: 5px;
  border: solid 1px #5fba47;
  font-family: Muli;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  color: #5fba47;
  background-color: #fff;
}
.ViewRFI:hover {
  cursor: pointer;
  background-color: #5fba47;
  color: #fff;
}
.RespondButton {
  height: 41px;
  padding: 8px;
  border-radius: 5px;
  border: solid 1px #f3f3f3;
  background-color: #f3f3f3;
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: #101650;
}
.RespondButton:hover {
  cursor: pointer;
  background-color: #0674b9;
  color: #fff;
}
.cancelButton {
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  font-family: Muli;
  font-size: 13px;
  text-align: center;
  color: #0674b9;
  border: 0;
}
.cancelButton:hover {
  cursor: pointer;
  color: #fff;
  background-color: #0674b9;
}
.SendButton {
  width: 71px;
  font-family: Muli;
  font-size: 13px;
  text-align: center;
  color: #fff;
  padding: 8px;
  border-radius: 5px;
  background-color: #0674b9;
  border: 0;
}
.SendButton:hover {
  cursor: pointer;
  color: #fff;
  background-color: #101650;
}
.SendButton:disabled {
  color: #fff;
  background-color: #707070;
}
.SendButton:disabled:hover {
  cursor: unset;
  color: #fff;
  background-color: #707070;
}
.notCheckedSub {
  width: 23px;
  height: 23px;
  border: solid 1px rgba(206, 210, 225, 0.97);
  background-color: #fff;
}
.notCheckedSub:hover {
  cursor: not-allowed;
  pointer-events: none;
}
.RaiseButtonSub {
  height: 41px;
  padding: 8px;
  border-radius: 5px;
  border: solid 1px #f3f3f3;
  background-color: #f3f3f3;
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: #101650;
  opacity: 0.4;
}
.RaiseButtonSub:hover {
  cursor:not-allowed;
}