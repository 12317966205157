.trade {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  color: #101650;
}
