.Production-Order-Code {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.-of-4 {
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.Rectangle-4027 {
  padding: 7px 0px 12px 10px;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(45, 164, 208, 0.3);
  background-color: #fff;

  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.shape-rectangle {
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(45, 164, 208, 0.3);
  background-color: #fff;
  padding: 5px 0px 5px 0px;
}

.Rectangle-4119 {
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(45, 164, 208, 0.3);
  background-color: #fff;

  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #101650;
}

.TOTAL-NO-OF-BARS {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.machine-status {
  padding: 8px 8px 8px 8px;
  opacity: 0.6;
  border-radius: 5px;
  background-color: #fff;

  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #101650;
}

.next-shape {
  font-family: Muli;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.Rectangle-4129 {
  padding: 8.8px 15px 8px 15px;
  opacity: 0.7;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(45, 164, 208, 0.3);
  background-color: #fff;
}

.Produced {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #101650;
}

.close-button {
  padding: 6px 17px 6px 17px;
  border-radius: 5px;
  background-color: #0674b9;
  border: 0;
  font-family: Muli;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.close-button:hover {
  cursor: pointer;
}

.close-button:disabled {
  cursor: default;
  background-color: #707070;
  color: #fff;
  border: solid 1px #707070;
}


.Do-you-want-to-reprint {
  font-family: Muli;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.52;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.confirm-button {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #0674b9;
  padding: 12px 26px;
  border-radius: 5px;
  background-color: #fff;
}

.decline-button {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #fff;

  padding: 12px 31px 12px 27px;
  border-radius: 5px;
  border: solid 1px #fff;
}

.confirm-button:hover {
  cursor: pointer
}

.decline-button:hover {
  cursor: pointer;
}

.Production-Code {
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.Rectangle-4045 {
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(45, 164, 208, 0.3);
  border: solid 1px #101650;
  padding: 7px 15.2px 6.6px 15.2px;

  font-family: Muli;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.Rectangle-4027 {
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(45, 164, 208, 0.3);
  background-color: #fff;
}

.TOTAL-WEIGHT {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.Rectangle-40 {
  padding: 7px 0px 12px 10px;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
  background-color: white;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.TOTAL-WEIGHT-VALUES {
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.total-style {
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.Label-name-Labe-size {
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.Height-127mm-Width-75mm {
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.RM-Tag-No {
  font-family: Muli;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.SPLR-Address {
  font-family: Muli;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: #101650;
}

.print-button {
  padding: 12px 32px;
  border-radius: 5px;
  background-color: #0674b9;

  font-family: Muli;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.print-button:hover {
  cursor: pointer;
}

.save-button {
  border-radius: 5px;
  background-color: #101650;
  font-family: Muli;
  font-size: 12px;
  text-align: center;
  color: #fff;
  border: 0;
  padding: 5px;
}
.save-button:hover {
  cursor: pointer;
}
.cancel-button {
  padding: 5px;
  border-radius: 4px;
  border: solid 1px #f2f2f2;
  background-color: #fff;
  font-family: Muli;
  font-size: 12px;
  text-align: center;
  color: #0674b9;
}
.cancel-button:hover{
  cursor: pointer;
}